import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./parallaxFaq.css";
import "../../../styles/Fonts.scss";

const faqs = [
  {
    question: "On certified tracks, who will you be sending the music to?",
    answer:
      "SML has a network of relationships with music supervisors, content producers, and show runners who know our reputation for sync-friendly music. SML regularly sends links to new music these folks. Further, our on-staff music supervisors are actively engaged in music searches and pitch our catalog every week.",
  },
  {
    question: "Do you ever offer help with the production of a song?",
    answer:
      "Yes! We have placed loads of songs and regularly create custom tracks for projects. Your membership includes production advice and suggestions. Also song production is available for separate fees, not included in the membership.",
  },
  {
    question:
      "What, if any, guarantees do you make for getting a song placed in a show, film or theatrical trailer?",
    answer:
      "Gonna be honest. Can't guarantee your song will be placed. Nobody can despite what you might have heard. But what we can guarantee is, if you are able to adapt and make the suggested changes, your songs WILL get heard by those who pitch songs all day long.",
  },
  {
    question:
      "When you certify a track for distribution, do you take any ownership of the song or any portion of fees from the song placement?",
    answer:
      "No! From submission to placement, we focus on leveling up your song quality and providing you the support you might never have gotten from anyone else.",
  },
  {
    question: "What does it mean to “certify” a track?",
    answer:
      "Certifying a track is just our way of saying “this track is 100% good to go for sync.” Some submissions we get need careful review and revisions. Others are ready to certify straight away and will go into our non-exclusive catalog as yet another way of reaching more contacts for song placement.",
  },
];

const ParallaxFaq = () => {
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="faq-container">
      <div className="faq-title">FAQ</div>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className={expanded === index ? "active-faq" : ""}>
            <Accordion
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParallaxFaq;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  background: "transparent",
  "& p": { fontSize: "inherit !important" },
  color: "inherit",
  border: `0`,
  borderRadius: "15px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "inherit",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon />}
    sx={{
      minHeight: "60px",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
