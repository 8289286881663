import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginRequest,
  socialLoginRequest,
} from "../../redux/actions/authActions";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import "../../styles/Fonts.scss";
import "./LoginPage.css";
import closeIcon from "../../../src/assets/Images/closeIcon.svg";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLLinkButton from "../../components/Shared/SMLLinkButton/SMLLinkButton";
import SMLErrorBox from "../../components/Shared/SMLErrorBox/SMLErrorBox";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import SignUpPage from "../SignUpPage/SignUpPage";
import { BiQuestionMark } from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import ForgetPassword from "../../components/common/forget-password/ForgetPassword";

const Login = ({ title, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpShow, setSignUpShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showForgetPass, setShowForgetPass] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile_type = "artist";
  const { user, loading, error } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.profile_type === "artist") {
      localStorage.setItem("username", user.name);
      localStorage.setItem("emails", user.email);
      localStorage.setItem("profile_type", user.profile_type);
      navigate("/dashboard", { state: { username: user.name } });
    }
  }, [user, navigate]);

  const handleLoginContainerClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (error) setLoginError(true);
  }, [error]);

  useEffect(() => {
    setLoginError(false);
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    } else {
      setPasswordError("");
    }

    dispatch(loginRequest(email, password, profile_type));
  };

  const handleSignUpClick = (e) => {
    setSignUpShow(true);
  };
  const handleForgetPassClick = (e) => {
    e.preventDefault();
    setShowForgetPass(true);
  };

  const responseFacebook = (response) => {
    const profile = {
      name: response.name,
      email: response.email,
    };
    localStorage.setItem("username", profile.name);
    localStorage.setItem("emails", profile.email);
    localStorage.setItem("isFacebookLoggedIn", "true");
    navigate("/dashboard");
  };

  const handleGoogleLogin = (credentialResponse) => {
    dispatch(
      socialLoginRequest(credentialResponse.credential, "google", "artist")
    );
  };
  const handleFacebookLogin = (credentialResponse) => {
    dispatch(
      socialLoginRequest(credentialResponse.credential, "facebook", "artist")
    );
  };

  const loginWithFacebook = () => {
    const callbackUrl = `${window.location.origin}`;
    const facebookAppId = "1022330112917385";
    const targetUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${facebookAppId}&redirect_uri=${encodeURIComponent(
      callbackUrl
    )}&response_type=token&scope=email,public_profile`;

    window.location.href = targetUrl;
  };

  return showForgetPass ? (
    <ForgetPassword onClose={onClose} />
  ) : signUpShow ? (
    <SignUpPage onClose={onClose} />
  ) : (
    <Modal show={true} centered className="custom-login-modal">
      <div className="login-containers" onClick={handleLoginContainerClick}>
        <form
          className="login-card"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin(e);
            }
          }}
        >
          {loading && (
            <div className="spinner-overlay">
              <Bars
                height="80"
                width="80"
                color="#23f0c7"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          <div className="Login-close-div" onClick={onClose}>
            <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
          </div>

          <div className="login-main">
            <div className="login-label-div">
              <SMLLabel value={"Log In"} style="Mainheader" />
            </div>
            <div className="Login-content" onSubmit={handleLogin}>
              <div className="login-controls">
                <div className="login-fields">
                  {loginError && <span className="error">{error}</span>}
                  <SMLLabel value="Email" style="title2"></SMLLabel>
                  <SMLTextBox
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <span className="button1 error">{emailError}</span>
                  )}
                </div>
                <div className="login-fields">
                  <div className="login-groupby">
                    <label className="title2">Password</label>
                    <SMLLinkButton
                      handleClickEvent={handleForgetPassClick}
                      value={
                        <span className="forgotPassword">
                          Forgot Password
                          <BiQuestionMark className="questionMark" />
                        </span>
                      }
                    />
                  </div>
                  <div className="passwordfield">
                    <SMLTextBox
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className="toggle-password-visibility-login"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </span>
                  </div>
                  {passwordError && (
                    <span className="button1 error">{passwordError}</span>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {" "}
                    <input
                      style={{
                        height: "14px",
                        width: "14px",
                        cursor: "pointer",
                      }}
                      type="checkbox"
                      id="Remember"
                    ></input>
                    <label
                      className="forgotPassword sub-subtitle2"
                      for="Remember"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>
              </div>
              <div className="login-controls1">
                <div className="login-fields1">
                  <SMLButton
                    style="SMLButton1"
                    value="Log In"
                    handleClickEvent={handleLogin}
                  />
                  <div className="login-groupby">
                    <div className="div-line"></div>
                    <p className="or">or</p>
                    <div className="div-line"></div>
                  </div>
                </div>
                <div className="login-fields1">
                  <div className="login-groupbyBtns google">
                    <div>
                      <GoogleLogin
                        onSuccess={(codeResponse) => {
                          handleGoogleLogin(codeResponse);
                        }}
                        onError={() => console.log("Login Failed")}
                        setOneTap={false}
                        useOneTap={false}
                        size="large"
                        width="209"
                      />
                    </div>
                    <FacebookLogin
                      appId={"1022330112917385"}
                      fields="name,email,picture"
                      callback={handleFacebookLogin}
                      render={(renderProps) => (
                        <SMLButtonWithIcon
                          type="button"
                          value="Sign up with Facebook"
                          icon="Facebook"
                          onClick={() => {
                            renderProps.onClick();
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="login-card-end">
                    <div className="login-groupbyBtns">
                      <span style={{ cursor: "pointer" }}>Not a member?</span>

                      <button
                        className="btn-no-margin button1"
                        type="button"
                        onClick={handleSignUpClick}
                      >
                        Sign up
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Login;
