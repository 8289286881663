import React, { useState, useEffect } from "react";
import "./parallaxDynamic.css";

const Typewriter = ({ texts, period }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    let timer;
    const updateText = () => {
      const currentText = texts[textIndex];
      if (!isDeleting) {
        setDisplayedText((prevText) => {
          if (prevText === currentText) {
            setIsDeleting(true);
          }
          return currentText.substring(0, prevText.length + 1);
        });
      } else {
        setDisplayedText((prevText) => {
          if (prevText === "") {
            setIsDeleting(false);
            setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
          }
          return currentText.substring(0, prevText.length - 1);
        });
      }
    };

    timer = setTimeout(updateText, period);
    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, texts, textIndex, period]);

  return (
    <span className="typewriter">
      {displayedText}
      <span className="cursor">|</span>
    </span>
  );
};

const DynamicText = (props) => {
  return (
    <div className="content-parallax">
      <h1 className="dynamic-text">
        <div className="typewriter-container">
          <span>For </span>
          <Typewriter
            texts={["artists", "songwriters", "composers", "producers"]}
            period={100}
          />
        </div>
        <span> who want to get noticed for their excellence.</span>
      </h1>
    </div>
  );
};

export default DynamicText;
