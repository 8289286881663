import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import "./NotifPanel.css";
import notification from "../../../assets/Images/notifications.svg";
import Union from "../../../assets/Images/Union.png";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import SMLButton from "../../Shared/SMLButton/SMLButton";

const NotifPanel = () => {
  const [showNotif, setShowNotif] = useState(false);
  const notifRef = useRef(null);

  const handleNotif = () => {
    setShowNotif(!showNotif);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notifRef.current && !notifRef.current.contains(event.target)) {
        setShowNotif(false);
      }
    };

    if (showNotif) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showNotif]);

  return (
    <>
      <button
        style={{ backgroundColor: "transparent" }}
        className="notif-bell"
        onClick={handleNotif}
      >
        <img src={notification} alt="Bell" />
      </button>
      {showNotif && (
        <div ref={notifRef} className="notif-panel">
          <Card className="notif-card">
            <div className="notif-card-content">
              <div className="d-flex justify-content-start align-items-center">
                <img src={notification} alt="Notification" />
                <SMLLabel
                  value="Your Track has been reviewed!"
                  style="title2"
                />
              </div>
              <div>
                <SMLButton
                  style="NotesBtn"
                  value="View Feedback"
                  textColor="#97180c"
                />
              </div>
            </div>
          </Card>
          <Card className="notif-card">
            <div className="notif-card-content">
              <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
                <img src={Union} alt="Notification" />
                <SMLLabel value="Congratulations!" style="title2" />
              </div>
              <SMLLabel
                value="Your track has been certified and is currently being distributed"
                style=""
              />
              <div className="mt-2">
                <SMLButton
                  style="NotesBtn"
                  value="View Feedback"
                  textColor="#97180c"
                />
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default NotifPanel;
