import axios from "axios";
import api from "../api";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const resetPassword = async ({ oldPassword, newPassword }) => {
  const accessToken = localStorage.getItem("token");

  if (!accessToken) {
    console.error("No token found. Please log in");
    return;
  }
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await api.post(
      `${BASE_URL}/auth/change-password`,
      {
        old_password: oldPassword,
        new_password: newPassword,
      },
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
