import axios from "axios";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const refreshToken = async ({ access_token, refresh_token }) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/refresh`, {
      access_token,
      refresh_token,
    });
    const newToken = response.data.access_token;
    const newExpiryTime = `${response.data.expires_in}`;
    const expirationTime = new Date().getTime() + newExpiryTime * 1000;
    localStorage.setItem("token", newToken);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("token_expiration", expirationTime);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error("Login failed");
    }
    console.log(error);
    throw error;
  }
};
