import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackFeedback.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { Row, Col } from "react-bootstrap";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import {
  assignSuperVisorRequest,
  getSingleAritstRequest,
} from "../../redux/actions";

import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Edit, Close, ArrowBack } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";
import AddNoteIcon from "../../assets/Icons/addNote.svg";
import EnhancedCustomTimePicker from "../../components/Shared/TimeInput/EnhancedCustomTimePicker";

import {
  addCommentApi,
  deleteCommentApi,
  getAllCommentsApi,
  updateCommentApi,
} from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime, parseTime } from "../Reviews/TrackUnderReview";
import SupAssignPopup from "./SupAssignPopup";
import {
  RESET_ASSIGNED_SUPERVISOR,
  RESET_GET_FEEDBACK,
} from "../../redux/actions/actionTypes";
import { Tooltip } from "react-tooltip";
import TrackEvaluatedResults from "../../SupervisorPage/TrackEvaluationPage/TrackEvaluatedResults";
import { margin, width } from "@mui/system";
import SMLLogo from "../../assets/Images/SMLLogo.svg";
const SubmitFeedback = (props) => {
  const location = useLocation();
  const status = location.state?.status;
  const fromUserPortal = location.state?.fromUserPortal;
  const fromDashboard = location.state?.fromDashboard;
  const isMaster = localStorage.getItem("isMaster");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);

  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);

  const assigned = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id ?? null
  );
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [isCompleteEnabled, setIsCompleteEnabled] = useState(false);
  const [isAssignEnabled, setIsAssignEnabled] = useState(true);
  const [buttonText, setButtonText] = useState("Complete Evaluation");
  const [showAssignPopup, setShowAssignPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(status);
  const [selfAssign, setSelfAssign] = useState(false);
  const [done, setDone] = useState(false);
  const [isEditClick, setIsEditClick] = useState(false);
  const [commentEnabled, setcommentEnabled] = useState(false);
  const supId = useSelector(
    (state) => state.sml.allArtistFetched?.artists?._id
  );
  const assignedSupId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.assigned_supervisor_id
  );
  const supFromAssigned = useSelector(
    (state) => state.assignSupervisor?.assigned?.assigned_supervisor_id
  );
  const assignedStatus = useSelector(
    (state) => state.assignSupervisor?.assigned?.status?.code
  );
  const handleAssign = () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    setShowAssignPopup(!showAssignPopup);
  };
  const handleStartAssign = async () => {
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });

    try {
      const response = dispatch(
        assignSuperVisorRequest({ trackId, supervisor: supId })
      );
      console.log("Supervisor assigned:", response);

      setSelfAssign(true);
      setCurrentStatus(assignedStatus);
    } catch (error) {
      console.error("Error assigning supervisor:", error);
    }
  };

  useEffect(() => {
    if (assignedSupId && isMaster === "false" && selfAssign === true) {
      setCurrentStatus(assignedStatus);
      setIsCompleteEnabled(true);
    }
  }, [assignedStatus]);
  const handleTrackEvaluation = () => {
    if (
      (buttonText === "View Evaluation" &&
        status === "final_feedback_submitted") ||
      ((buttonText === "View Evaluation" ||
        buttonText === "Final Evaluation") &&
        status === "feedback_submitted" &&
        isMaster === "false")
    ) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluatedPage/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else if (buttonText === "View Evaluation" && fromUserPortal) {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluated/${trackId}`, {
        state: { fromUserPortal, status },
      });
    } else {
      dispatch({ type: RESET_GET_FEEDBACK });
      navigate(`/TrackEvaluationPage/${trackId}`, {
        state: { fromUserPortal, status, assignedSupId },
      });
    }
  };

  useEffect(() => {
    if (assigned && assigned.length > 0) {
      if (
        isMaster === "true" &&
        currentStatus !== "submitted" &&
        supFromAssigned === supId
      ) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      } else if (isMaster === "false" && currentStatus !== "submitted") {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      } else if (
        supFromAssigned === supId &&
        isMaster === "true" &&
        currentStatus === "submitted"
      ) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
    }
  }, [assigned]);
  const statusFromTrack = useSelector(
    (state) => state.uploadTrack?.singleTrack?.status?.code
  );
  useEffect(() => {
    setCurrentStatus(statusFromTrack);
  }, [statusFromTrack]);
  useEffect(() => {
    if (currentStatus === "final_feedback_submitted") {
      setIsCompleteEnabled(true);
      setButtonText("View Evaluation");
    } else if (!fromUserPortal && currentStatus === "in_review") {
      if (assignedSupId === supId) {
        setIsAssignEnabled(false);
        setIsCompleteEnabled(true);
      }
    } else if (currentStatus === "feedback_submitted") {
      if (isMaster === "false") {
        setButtonText("View Evaluation");
      } else {
        setIsCompleteEnabled(true);
        setButtonText("Final Evaluation");
      }
    } else if (currentStatus === "final_feedback_submitted") {
      if (isMaster === "false") {
        setButtonText("View Evaluation");
      } else {
        setIsCompleteEnabled(true);
        setButtonText("View Evaluation");
      }
    } else if (
      supFromAssigned === supId &&
      isMaster === "false" &&
      currentStatus === "submitted"
    ) {
      setIsCompleteEnabled(true);
    }
  }, [
    currentStatus,
    fromUserPortal,
    isAssignEnabled,
    isCompleteEnabled,
    showAssignPopup,
  ]);

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    dispatch(getSingleTrackRequest(trackId));
  }, [dispatch, fromUserPortal, trackId]);

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [trackUrl, setTrackUrl] = useState();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    url: trackUrl,
    height: 150,
    plugins: useMemo(() => [regions], [regions]),
  });
  async function fetchTrackUrl(trackId) {
    try {
      const { audio_signed_url } = await downloadTrackApi({ trackId });
      setTrackUrl(audio_signed_url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching track URL:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchTrackUrl(trackId);
  }, [trackId]);

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        console.log("Wavesurfer is ready");
        setDone(true);
        setIsLoading(false);
      });

      wavesurfer.on("error", (error) => {
        if (error?.name === "AbortError") {
          console.warn("Audio loading aborted");
        } else {
          console.error("Error loading the audio", error);
        }
        setIsLoading(false);
      });
    }

    return () => {
      if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    fetchTrackUrl(trackId);
    getAllCommentsApi(trackId).then((uploadedComments) => {
      setComments(
        uploadedComments.map(
          ({ _id, start_time, end_time, comment }, index) => ({
            id: _id,
            time: start_time === end_time ? start_time : [start_time, end_time],
            transcript: comment,
            idSuffix: `${index}-${start_time}`,
          })
        )
      );
    });
  }, [trackId]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);

  const [focusedComment, setFocusedComment] = useState(null);
  const [highlightComment, setHighlightComment] = useState(null);
  const focusedCommentContainer = useRef(null);
  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleClickOutside = (event) => {
    if (
      focusedCommentContainer.current &&
      !focusedCommentContainer.current.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setcommentEnabled(false);
      setIsEditClick(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsEditClick(false);
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setcommentEnabled(false);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState(null);

  useEffect(() => {
    if (
      ((currentStatus === "feedback_submitted" && isMaster === "true") ||
        currentStatus === "in_review" ||
        (currentStatus === "submitted" && isMaster === "false")) &&
      !!wavesurfer
    ) {
      regions.enableDragSelection({
        color: "#ff440540",
      });

      regions.on("region-created", (newRegion) => {
        const allRegions = regions.getRegions();
        allRegions.forEach((region) => {
          if (newRegion !== region) region.remove();
        });

        setSelectedPart("region");
        setActiveRegion([newRegion.start, newRegion.end]);
        setCommentPosition(newRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-updated", (updatedRegion) => {
        setSelectedPart("region");
        setActiveRegion([updatedRegion.start, updatedRegion.end]);
        setCommentPosition(updatedRegion.start);
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });

      regions.on("region-clicked", (region, e) => {
        e.stopPropagation();
        region.play();
      });
    }
  }, [wavesurfRef, wavesurfer, regions]);

  useEffect(() => {
    if (!!wavesurfer)
      wavesurfRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    wavesurfRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const handleCommentSubmit = async (event) => {
    if (event.key === "Enter" && currentComment.trim()) {
      let newComment;
      setFocusedComment(null);
      setcommentEnabled(false);
      const commentData = {
        trackId,
        comment: currentComment,
      };

      if (selcetedPart === "point") {
        commentData.startTime = activePosition;
        commentData.endTime = activePosition;
        newComment = {
          time: activePosition,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
      } else if (selcetedPart === "region") {
        commentData.startTime = activeRegion[0];
        commentData.endTime = activeRegion[1];
        newComment = {
          time: activeRegion,
          transcript: currentComment,
          idSuffix: `${comments.length}-${activePosition}`,
        };
      }

      try {
        if (
          currentStatus === "submitted" &&
          assignedSupId === supId &&
          isMaster === "false"
        )
          await handleStartAssign();
        const response = await addCommentApi(commentData);
        console.log("API response:", response);
        if (response) {
          getAllCommentsApi(trackId).then((uploadedComments) => {
            setComments(
              uploadedComments.map(
                ({ _id, start_time, end_time, comment }, index) => ({
                  id: _id,
                  time:
                    start_time === end_time
                      ? start_time
                      : [start_time, end_time],
                  transcript: comment,
                  idSuffix: `${index}-${start_time}`,
                })
              )
            );
          });
        } else {
          console.error("Added comment is undefined");
        }

        setShowCommentBox(false);
        setShowAddCommentButton(false);
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
    }
  };
  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const handleCloseAssignPopup = () => {
    setShowAssignPopup(false);
    dispatch(getSingleTrackRequest(trackId));
  };
  useEffect(() => {
    if (currentStatus !== "final_feedback_submitted") {
      if (assignedSupId === supId && assignedSupId !== undefined) {
        setIsCompleteEnabled(true);
      } else {
        if (currentStatus === "in_review") {
          setIsCompleteEnabled(false);
        }
      }
    }
  }, [showAssignPopup, assignedSupId, getSingleTrackRequest]);
  const handleStatus = () => {
    if (currentStatus === "submitted") {
    }
  };
  return (
    <>
      {showAssignPopup && (
        <SupAssignPopup
          trackId={trackId}
          onClose={handleCloseAssignPopup}
          currentStatus={currentStatus}
          handleStatus={handleStatus}
          assignedSupervisor={assigned ? true : false}
        />
      )}
      <div
        className="full-screen-background vh-100 px-3"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {/* {!fromUserPortal && <SUPHeader />} */}
        <div className="px-5 mt-3">
          <img className="headerLogo-trackf px-3" src={SMLLogo} />
        </div>
        <div
          className="trackFeedback-main"
          // style={{ display: "flex", flexDirection: "column", flex: "1 1" }}
        >
          <div
            className="track-main-div"
            style={{
              paddingTop: "15px",
              position: "relative",
              marginLeft: "4rem",
            }}
          >
            <div className="d-flex align-items-center justify-content-start gap-2 mt-3 mb-4">
              <SMLLabel
                style="title1 mb-1"
                value={
                  track?.title.length > 10 ? (
                    <>
                      {track?.title.substring(0, 10)}
                      <a
                        data-tooltip-id="trackTitle-feedback"
                        place="top"
                        effect="solid"
                        data-tooltip-content={track?.title}
                        data-tooltip-place="bottom"
                        multiline
                      >
                        ....
                      </a>
                      <Tooltip id="trackTitle-feedback" />
                    </>
                  ) : (
                    <>{track?.title}</>
                  )
                }
              />
              <SMLOptionQuestion
                style="SMLSecondaryButton1"
                type="button"
                value="About Track"
                handleClickEvent={handleAboutTrack}
              />
              {!fromUserPortal && (
                <SMLOptionQuestion
                  style="SMLSecondaryButton1"
                  type="button"
                  value="Artist Profile"
                  handleClickEvent={handleArtistProfile}
                />
              )}
            </div>

            <div className="d-flex align-items-center justify-content-start gap-5">
              <div className="d-flex flex-column align-items-start justify-content-center gap-3">
                <img
                  src={trackImg}
                  alt="Track"
                  width="141"
                  height="141"
                  className="img-fluid"
                />
              </div>
              <Col className="d-flex align-items-end">
                <div>
                  {wavesurfer &&
                    comments.map((comment, index) => (
                      <div
                        onClick={() => {
                          document
                            .getElementById(`transcript-${comment.idSuffix}`)
                            .scrollIntoView({ behavior: "smooth" });
                          setFocusedComment(index);
                          regions.clearRegions();
                        }}
                        key={`comment-${comment.idSuffix}`}
                        style={{
                          position: "absolute",
                          left: `calc(${
                            ((typeof comment.time === "object"
                              ? comment.time[0]
                              : comment.time) /
                              wavesurfer.getDuration()) *
                            87
                          }% + 9rem)`,
                          top: "16rem",
                          height: "24px",
                          width: " 24px",
                          cursor: "pointer",
                          zIndex: "99",
                        }}
                      >
                        <img
                          src={
                            focusedComment === index
                              ? CommentIcon
                              : CommentGrayIcon
                          }
                          alt="Comment"
                        />
                      </div>
                    ))}
                </div>
                <div
                  style={{ width: "95%" }}
                  id="waveform"
                  ref={wavesurfRef}
                ></div>
                <div style={{ position: "relative", height: "20px" }}>
                  {showCommentBox && focusedComment === null && (
                    <div
                      className="comment-box"
                      style={{ left: commentBoxPosition }}
                    >
                      <div className="comment-header">
                        <span className="timestamp">
                          <div style={{ height: "24px", width: " 24px" }}>
                            <img src={CommentIcon} alt="Comment" />
                          </div>
                          {selcetedPart === "point"
                            ? `${formatTime(activePosition)}`
                            : selcetedPart === "region"
                            ? `${formatTime(activeRegion[0])} - ${formatTime(
                                activeRegion[1]
                              )}`
                            : ""}
                        </span>
                        <IconButton
                          sx={{ padding: 0.7 }}
                          onClick={() => {
                            if (selcetedPart === "region")
                              regions.clearRegions();
                            setShowCommentBox(false);
                            setShowAddCommentButton(false);
                          }}
                        >
                          <Close sx={{ height: 20, width: 20 }} />
                        </IconButton>
                      </div>
                      <label>{user?.name}</label>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "20px" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "100%",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      marginLeft: "3rem",
                    }}
                  >
                    <div className="detail-results">
                      <div>
                        <TrackEvaluatedResults fromFeedback={true} />
                      </div>
                      <div
                        id="descriptions-detail"
                        style={{ paddingTop: "30px" }}
                      >
                        {comments.map((comment, index) =>
                          focusedComment !== index ||
                          currentStatus === "final_feedback_submitted" ||
                          (currentStatus === "feedback_submitted" &&
                            isMaster === "false") ? (
                            <div
                              id={`transcript-${comment.idSuffix}`}
                              key={`transcript-${comment.idSuffix}`}
                              className="input-track-note"
                              style={{
                                opacity:
                                  focusedComment === index ||
                                  focusedComment === null
                                    ? 1
                                    : 0.25,
                              }}
                              onClick={() =>
                                reDirectToComment(
                                  typeof comment.time === "number"
                                    ? comment.time
                                    : comment.time[0]
                                )
                              }
                            >
                              <div className="comment-header">
                                <span className="timestamp">
                                  <div
                                    style={{ height: "24px", width: " 24px" }}
                                  >
                                    <img src={CommentIcon} alt="Comment" />
                                  </div>
                                  {typeof comment.time === "object"
                                    ? `${formatTime(
                                        comment.time[0]
                                      )} - ${formatTime(comment.time[1])}`
                                    : `${formatTime(comment.time)}`}
                                </span>

                                {(currentStatus === "in_review" ||
                                  (currentStatus === "feedback_submitted" &&
                                    isMaster === "true")) && (
                                  <div>
                                    <IconButton
                                      sx={{ padding: 1 }}
                                      onClick={() => {
                                        setFocusedComment(index);
                                        setcommentEnabled(true);
                                        setIsEditClick(true);
                                      }}
                                    >
                                      <Edit
                                        sx={{ width: "16px", height: "16px" }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      sx={{ padding: 0.7 }}
                                      onClick={() => {
                                        if (comment.id)
                                          deleteCommentApi(comment.id);
                                        setComments([
                                          ...comments.slice(0, index),
                                          ...comments.slice(index + 1),
                                        ]);
                                      }}
                                    >
                                      <Close sx={{ width: 20, height: 20 }} />
                                    </IconButton>
                                  </div>
                                )}
                              </div>
                              <div className="content">
                                <div className="author">{user?.name}</div>
                                <div className="comment-content">
                                  {comment.transcript}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              ref={focusedCommentContainer}
                              id={`transcript-${comment.idSuffix}`}
                              key={`transcript-${comment.idSuffix}`}
                              className="input-track-note"
                            >
                              {isEditClick ? (
                                <>
                                  <div className="comment-header">
                                    <span className="timestamp">
                                      <div
                                        style={{
                                          height: "24px",
                                          width: " 24px",
                                        }}
                                      >
                                        <img src={CommentIcon} alt="Comment" />
                                      </div>
                                      {typeof comment.time === "object"
                                        ? `${formatTime(
                                            comment.time[0]
                                          )} - ${formatTime(comment.time[1])}`
                                        : `${formatTime(comment.time)}`}
                                    </span>
                                    {(currentStatus === "in_review" ||
                                      (currentStatus === "feedback_submitted" &&
                                        isMaster === "true")) && (
                                      <IconButton
                                        sx={{ padding: 0 }}
                                        onClick={() => {
                                          regions.clearRegions();
                                          setFocusedComment(null);
                                          setcommentEnabled(false);
                                        }}
                                      >
                                        <Close sx={{ height: 20, width: 20 }} />
                                      </IconButton>
                                    )}
                                  </div>
                                  <label>{user?.name}</label>
                                  <textarea
                                    ref={inputRef}
                                    type="text"
                                    value={comment.transcript}
                                    onChange={(e) => {
                                      if (
                                        currentStatus === "in_review" ||
                                        currentStatus === "feedback_submitted"
                                      ) {
                                        setComments([
                                          ...comments.slice(0, index),
                                          {
                                            ...comment,
                                            transcript: e.target.value,
                                          },
                                          ...comments.slice(index + 1),
                                        ]);
                                      }
                                    }}
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        setActiveRegion(null);
                                        setIsEditClick(false);
                                        setFocusedComment(null);
                                        setcommentEnabled(false);
                                        updateCommentApi({
                                          commentId: comment.id,
                                          comment: comment.transcript,
                                        });
                                      }
                                    }}
                                    placeholder="Type your note..."
                                    disabled={
                                      currentStatus ===
                                      ("final_feedback_submitted" &&
                                        "submitted")
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="comment-header">
                                    <span className="timestamp">
                                      <div
                                        style={{
                                          height: "24px",
                                          width: " 24px",
                                        }}
                                      >
                                        <img src={CommentIcon} alt="Comment" />
                                      </div>
                                      {typeof comment.time === "object"
                                        ? `${formatTime(
                                            comment.time[0]
                                          )} - ${formatTime(comment.time[1])}`
                                        : `${formatTime(comment.time)}`}
                                    </span>

                                    {(currentStatus === "in_review" ||
                                      (currentStatus === "feedback_submitted" &&
                                        isMaster === "true")) && (
                                      <div>
                                        <IconButton
                                          sx={{ padding: 1 }}
                                          onClick={() => {
                                            setFocusedComment(index);
                                            setcommentEnabled(true);
                                            setIsEditClick(true);
                                          }}
                                        >
                                          <Edit
                                            sx={{
                                              width: "16px",
                                              height: "16px",
                                            }}
                                          />
                                        </IconButton>

                                        <IconButton
                                          sx={{ padding: 0 }}
                                          onClick={() => {
                                            regions.clearRegions();
                                            setFocusedComment(null);
                                            setcommentEnabled(false);
                                          }}
                                        >
                                          <Close
                                            sx={{ height: 20, width: 20 }}
                                          />
                                        </IconButton>
                                      </div>
                                    )}
                                  </div>
                                  <label>{user?.name}</label>
                                  <div
                                    ref={inputRef}
                                    type="text"
                                    className="comment-content"
                                  >
                                    {comment.transcript}
                                  </div>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </Stack>
                <Stack className="trackPlay">
                  <TrackPlayer
                    track={track}
                    isPlaying={isPlaying}
                    wavesurfer={wavesurfer}
                    done={done}
                    commentEnabled={commentEnabled}
                  />
                </Stack>
              </Box>
            )}
          </div>
        </div>

        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}

        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={false}
              />
            </div>
          </div>
        )}
      </div>
      {(!wavesurfer || wavesurfer.getDuration() === 0) && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
    </>
  );
};

export default SubmitFeedback;
