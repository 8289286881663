import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
export const downloadTrackApi = async (trackId) => {
  try {
    const accessToken = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    trackId = trackId.trackId;
    const response = await api.get(
      `${BASE_URL}/tracks/${trackId}/download`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error downloading single track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
