import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../Shared/SMLTextBox/SMLTextBox";
import "./ForgetPassword.css";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordFinalRequest } from "../../../redux/actions";

//reset password route 
const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const username = query.get("email");
  const code = query.get("code");

  const validatePassword = (password, confirmPassword) => {
    if (!password || !confirmPassword) {
      return "Both fields are required.";
    }

    const isValid =
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[^\w\s]/.test(password) &&
      !/\s/.test(password);

    if (!isValid) {
      return "Password must be at least 8 characters long, contain upper/lowercase letters, a number, a special character, and must not contain spaces.";
    }

    if (password !== confirmPassword) {
      return "Passwords do not match.";
    }

    return null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validatePassword(newPassword, verifyPassword);
    if (!error) {
      dispatch(
        forgetPasswordFinalRequest({
          email: username,
          code,
          password: newPassword,
        })
      );
      setError("");
    } else {
      setError(error);
    }
  };
  const passReset = useSelector((state) => state.forgetPass.confirmResetPass);
  useEffect(() => {
    if (passReset?.error?.error !== undefined) {
      setError(passReset?.error?.error);
    } else if (passReset.message.length !== 0) {
      setIsSuccess(true);
    }
  }, [passReset]);
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="forgot-pass-modal"
    >
      <div className="reset-pass-form-group">
        <SMLLabel value="Reset Password" style="Mainheader1" />
        <div>
          <SMLLabel value="Enter New Password" />
          <div className="password-input-wrapper">
            <SMLTextBox
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>
        <div>
          <SMLLabel value="Confirm New Password" />
          <div className="password-input-wrapper">
            <SMLTextBox
              type={showVerifyPassword ? "text" : "password"}
              value={verifyPassword}
              onChange={(e) => setVerifyPassword(e.target.value)}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowVerifyPassword(!showVerifyPassword)}
            >
              {showVerifyPassword ? <FaEye /> : <FaEyeSlash />}
            </span>
          </div>
        </div>
        {error && (
          <div className="error-box">
            <SMLLabel value={error} style="error" />
          </div>
        )}
      </div>
      <div className="forget-pass-footer">
        <SMLButton
          value="Continue"
          style={`SMLButton2 SMLButton-Orange`}
          handleClickEvent={handleSubmit}
        />
      </div>
      {isSuccess && <div>"Done"</div>}
    </Modal>
  );
};

export default ResetPassword;
