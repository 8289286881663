import React, { useEffect, useMemo, useRef, useState } from "react";
import "../../styles/Fonts.scss";
import "../Supervisor-Dashboard/TrackFeedback.css";
import "../../styles/Fonts.scss";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import { Col } from "react-bootstrap";
import trackImg from "../../assets/Images/TrackRectangle.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import ArtistProfile from "../../components/UI/artistProfile/artistProfile";
import AboutTrack from "../../components/UI/aboutTrack/aboutTrack";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTrackRequest } from "../../redux/actions/uploadTrackActions";
import { getSingleAritstRequest } from "../../redux/actions";
import SMlLogo from "../../assets/Images/SMLLogo.svg";
import { useWavesurfer } from "@wavesurfer/react";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { Box, Stack, IconButton } from "@mui/material";
import { Close, ArrowBack } from "@mui/icons-material";

import TrackPlayer from "../../components/common/track-details/TrackPlayer";
import CommentIcon from "../../assets/Icons/comment.svg";
import CommentGrayIcon from "../../assets/Icons/comment-grey.svg";

import { getAllCommentsApi } from "../../api/supervisor/comment";
import { downloadTrackApi } from "../../api/track/downloadTrackApi";

import "../Reviews/AudioGraph.css";
import { Bars } from "react-loader-spinner";
import { formatTime, parseTime } from "../Reviews/TrackUnderReview";
import HomeLayout from "../../components/Layout/HomeLayout";
import TrackEvaluationArtist from "../../SupervisorPage/TrackEvaluationPage/TrackEvaluationArtist";
import { Tooltip } from "react-tooltip";
import TrackFeedback from "./TrackFeedback";
const TrackFeedbackReceived = (props) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state?.uploadTrack?.error);
  const status = useSelector(
    (state) => state.uploadTrack?.singleTrack?.status?.code
  );
  const fromDashboard = location.state?.fromDashboard;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { trackId } = useParams();
  const track = useSelector((state) => state.uploadTrack?.singleTrack);
  const artistId = useSelector(
    (state) => state.uploadTrack?.singleTrack?.artist_id
  );
  const artist = useSelector((state) => state.singleArtist?.details);
  const [showArtistProfile, setShowArtistProfile] = useState(false);
  const [showAboutTrack, setShowAboutTrack] = useState(false);
  const [done, setDone] = useState(false);
  const [authed, setAuthed] = useState();
  useEffect(() => {
    if (isAuthenticated === "Unauthorized") {
      setAuthed(false);
    } else {
      setAuthed(true);
    }
  }, [dispatch]);

  const handleAboutTrack = () => {
    setShowAboutTrack(true);
  };

  const handleArtistProfile = () => {
    setShowArtistProfile(true);
  };

  const handleCloseArtistProfile = () => {
    setShowArtistProfile(false);
  };

  const handleCloseAboutTrack = () => {
    setShowAboutTrack(false);
  };

  useEffect(() => {
    dispatch(getSingleTrackRequest(trackId));
  }, [dispatch, trackId]);

  useEffect(() => {
    if (artistId) {
      dispatch(getSingleAritstRequest(artistId));
    }
  }, [artistId, dispatch]);

  const [trackUrl, setTrackUrl] = useState();
  const wavesurfRef = useRef(null);
  const regions = useMemo(() => RegionsPlugin.create(), []);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: wavesurfRef,
    waveColor: "#CBCBCB",
    progressColor: "#FF4405",
    cursorColor: "#FF4405",
    selectionColor: "#FF4405",
    barWidth: 3,
    url: trackUrl,
    height: 150,
    plugins: useMemo(() => [regions], [regions]),
  });
  console.log(status, "wavesurfer");
  async function fetchTrackUrl(trackId) {
    try {
      const { audio_signed_url } = await downloadTrackApi({ trackId });
      setTrackUrl(audio_signed_url);
    } catch (error) {
      console.error("Error fetching track URL:", error);
    }
  }
  useEffect(() => {
    fetchTrackUrl(trackId);
    // if (status === "final_feedback_submitted") {
    getAllCommentsApi(trackId).then((uploadedComments) => {
      setComments(
        uploadedComments.map(
          ({ _id, start_time, end_time, comment }, index) => ({
            id: _id,
            time: start_time === end_time ? start_time : [start_time, end_time],
            transcript: comment,
            idSuffix: `${index}-${start_time}`,
          })
        )
      );
    });
    // }
  }, [trackId]);
  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on("ready", () => {
        setDone(true);
        console.log("Wavesurfer is ready");
      });

      wavesurfer.on("error", (error) => {
        if (error?.name === "AbortError") {
          console.warn("Audio loading aborted");
        } else {
          console.error("Error loading the audio", error);
        }
      });
    }

    return () => {
      if (wavesurfer && wavesurfer.isReady && wavesurfer.destroy) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  useEffect(() => {
    return () => {
      if (wavesurfer) {
        wavesurfer.destroy();
      }
    };
  }, [wavesurfer]);

  const [comments, setComments] = useState([]);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAddCommentButton, setShowAddCommentButton] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const inputRef = useRef(null);
  const [commentPosition, setCommentPosition] = useState(0);
  const commentBoxPosition = useMemo(() => {
    if (!wavesurfRef.current) return 0;
    const rect = wavesurfRef.current.getBoundingClientRect();
    const x = (commentPosition / wavesurfer.getDuration()) * rect.width;
    return Math.min(x, rect.width - 300);
  }, [commentPosition, wavesurfer, wavesurfRef]);

  const [focusedComment, setFocusedComment] = useState(null);
  const focusedCommentContainer = useRef(null);

  useEffect(() => {
    if (!!wavesurfer && focusedComment !== null) {
      if (typeof comments[focusedComment].time === "number") {
        wavesurfer.setTime(comments[focusedComment].time);
      } else {
        regions.clearRegions();
        regions.addRegion({
          start: comments[focusedComment].time[0],
          end: comments[focusedComment].time[1],
          color: "#ff440540",
          resize: false,
        });
      }
    }
  }, [focusedComment, wavesurfer, regions, comments]);

  const handleClickOutside = (event) => {
    if (
      focusedCommentContainer.current &&
      !focusedCommentContainer.current.contains(event.target)
    ) {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      regions.clearRegions();
      setFocusedComment(null);
      setActivePosition(null);
      setActiveRegion(null);
      setShowCommentBox(false);
      setShowAddCommentButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [selcetedPart, setSelectedPart] = useState();
  const [activePosition, setActivePosition] = useState();
  const [activeRegion, setActiveRegion] = useState();

  useEffect(() => {
    if (!!wavesurfer)
      wavesurfRef.current.addEventListener("click", (event) => {
        if (selcetedPart === "region") regions.clearRegions();
        setSelectedPart("point");
        setActivePosition(wavesurfer.getCurrentTime());
        setCommentPosition(wavesurfer.getCurrentTime());
        setShowAddCommentButton(true);
        setShowCommentBox(false);
      });
  }, [
    wavesurfRef,
    wavesurfer,
    regions,
    setSelectedPart,
    setActivePosition,
    selcetedPart,
  ]);

  useEffect(() => {
    if (!showCommentBox) {
      setActivePosition(undefined);
      setActiveRegion(undefined);
      setSelectedPart(undefined);
      setCurrentComment("");
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showCommentBox]);

  const user = useSelector((state) => state.sml.allArtistFetched?.artists);
  const reDirectToComment = (timeInSeconds) => {
    if (wavesurfer) {
      wavesurfer.seekTo(timeInSeconds / wavesurfer.getDuration());
    }
  };
  return authed ? (
    status === "final_feedback_submitted" ? (
      <div
        className="full-screen pt-5"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          className="padding-0"
          style={{ display: "flex", flexDirection: "column", flex: "1 1" }}
        >
          <div
            className="track-main-div"
            style={{
              position: "relative",
              marginLeft: "40px",
            }}
          >
            <div className="d-flex justify-content-start">
              <img className="headerLogo-trackf" src={SMlLogo} alt="Image" />
            </div>
            <div className="d-flex align-items-center justify-content-start gap-2 mt-3 mb-4">
              <SMLLabel
                style="title1"
                value={
                  track?.title.length > 10 ? (
                    <>
                      {track?.title.substring(0, 10)}
                      <a
                        data-tooltip-id="trackTitle-details-final"
                        place="top"
                        effect="solid"
                        data-tooltip-content={track?.title}
                        data-tooltip-place="bottom"
                        multiline
                      >
                        ....
                      </a>
                      <Tooltip id="trackTitle-details-final" />
                    </>
                  ) : (
                    <>{track?.title}</>
                  )
                }
              />
              <SMLOptionQuestion
                style="SMLSecondaryButton1"
                type="button"
                value="About Track"
                handleClickEvent={handleAboutTrack}
              />
            </div>
            <div className="d-flex align-items-center justify-content-start gap-5">
              <div className="d-flex flex-column align-items-start justify-content-center gap-3">
                <img
                  src={trackImg}
                  alt="Track"
                  width="141"
                  height="141"
                  className="img-fluid"
                />
              </div>
              <Col className="d-flex align-items-end">
                <div>
                  {wavesurfer &&
                    comments.map((comment, index) => (
                      <div
                        onClick={() => {
                          document
                            .getElementById(`transcript-${comment.idSuffix}`)
                            .scrollIntoView({ behavior: "smooth" });
                          setFocusedComment(index);
                          regions.clearRegions();
                        }}
                        key={`comment-${comment.idSuffix}`}
                        style={{
                          position: "absolute",
                          left: `calc(${
                            ((typeof comment.time === "object"
                              ? comment.time[0]
                              : comment.time) /
                              wavesurfer.getDuration()) *
                            87
                          }% + 9rem)`,
                          top: "19rem",
                          height: "24px",
                          width: " 24px",
                          cursor: "pointer",
                          zIndex: "99",
                        }}
                      >
                        <img
                          src={
                            focusedComment === index
                              ? CommentIcon
                              : CommentGrayIcon
                          }
                          alt="Comment"
                        />
                      </div>
                    ))}
                </div>
                <div id="waveform" ref={wavesurfRef}></div>
                <div style={{ position: "relative", height: "20px" }}>
                  {showCommentBox && focusedComment === null && (
                    <div
                      className="comment-box"
                      style={{ left: commentBoxPosition }}
                    >
                      <div className="comment-header">
                        <span className="timestamp">
                          <div style={{ height: "24px", width: " 24px" }}>
                            <img src={CommentIcon} alt="Comment" />
                          </div>
                          {selcetedPart === "point"
                            ? `${formatTime(activePosition)}`
                            : selcetedPart === "region"
                            ? `${formatTime(activeRegion[0])} - ${formatTime(
                                activeRegion[1]
                              )}`
                            : ""}
                        </span>
                        <IconButton
                          sx={{ padding: 0.7 }}
                          onClick={() => {
                            if (selcetedPart === "region")
                              regions.clearRegions();
                            setShowCommentBox(false);
                            setShowAddCommentButton(false);
                          }}
                        >
                          <Close sx={{ height: 20, width: 20 }} />
                        </IconButton>
                      </div>
                      <label>{user?.name}</label>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
          <div
            className="track-main-div"
            style={{ flex: "1 1", paddingTop: "" }}
          >
            {track && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Stack
                  sx={{
                    flexGrow: 1,
                    height: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      height:
                        status === "final_feedback_status" ? "100%" : "auto",
                      maxHeight:
                        status === "final_feedback_status" ? "100%" : "none",
                      overflow: "hidden",
                      paddingTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="detail-results">
                      <TrackEvaluationArtist />
                      <div
                        id="descriptions-detail"
                        style={{ paddingTop: "30px" }}
                      >
                        {comments.map((comment, index) =>
                          focusedComment !== index ? (
                            <div
                              id={`transcript-${comment.idSuffix}`}
                              key={`transcript-${comment.idSuffix}`}
                              className="input-track-notes"
                              style={{
                                opacity: focusedComment !== null ? 0.25 : 1,
                              }}
                              onClick={() =>
                                reDirectToComment(
                                  typeof comment.time === "number"
                                    ? comment.time
                                    : comment.time[0]
                                )
                              }
                            >
                              <div className="comment-header">
                                <span className="timestamp">
                                  <div
                                    style={{ height: "24px", width: " 24px" }}
                                  >
                                    <img src={CommentIcon} alt="Comment" />
                                  </div>
                                  {typeof comment.time === "object"
                                    ? `${formatTime(
                                        comment.time[0]
                                      )} - ${formatTime(comment.time[1])}`
                                    : `${formatTime(comment.time)}`}
                                </span>
                              </div>
                              <div className="content">
                                <div className="author">{user?.name}</div>
                                <div className="comment-content">
                                  {comment.transcript}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              ref={focusedCommentContainer}
                              id={`transcript-${comment.idSuffix}`}
                              key={`transcript-${comment.idSuffix}`}
                              className="input-track-notes"
                            >
                              <div className="comment-header">
                                <span className="timestamp">
                                  <div
                                    style={{ height: "24px", width: " 24px" }}
                                  >
                                    <img src={CommentIcon} alt="Comment" />
                                  </div>
                                  {typeof comment.time === "object"
                                    ? `${formatTime(
                                        comment.time[0]
                                      )} - ${formatTime(comment.time[1])}`
                                    : `${formatTime(comment.time)}`}
                                </span>
                              </div>
                              <label>{user?.name}</label>
                              <div
                                ref={inputRef}
                                type="text"
                                className="content"
                              >
                                {comment.transcript}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <Stack
                    sx={{
                      position: "fixed",
                      right: "0",
                      bottom: "0",
                      width: "100%",
                    }}
                  >
                    <TrackPlayer
                      track={track}
                      isPlaying={isPlaying}
                      wavesurfer={wavesurfer}
                      done={done}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {(!wavesurfer || wavesurfer.getDuration() === 0) && (
              <div className="spinner-overlay">
                <Bars
                  height="80"
                  width="80"
                  color="#23f0c7"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
        {showArtistProfile && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <ArtistProfile
                onArtistProfileClose={handleCloseArtistProfile}
                artist={artist}
              />
            </div>
          </div>
        )}
        {showAboutTrack && (
          <div className="popup-background-blur">
            <div className="ArtistProfile-popup">
              <AboutTrack
                onAboutTrackClose={handleCloseAboutTrack}
                track={track}
                fromArtistPortal={true}
              />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="vh-100 d-flex justify-content-center align-items-center mt-5">
        <h1>
          Thank you for your patience! Your track is still under review and has
          not yet received any feedback. We appreciate your understanding!
        </h1>
      </div>
    )
  ) : (
    <div className="vh-100 d-flex justify-content-center align-items-center mt-5">
      You need to login first
    </div>
  );
};

export default TrackFeedbackReceived;
