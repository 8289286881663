import React, { useEffect, useState } from "react";
import "./parallaxLogin.css";
import logo from "../../../assets/Images/Logo.svg";
import SupervisionLogin from "../../../SupervisorPage/SupervisorLogin/SupervisorLogin";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";
import { useDispatch, useSelector } from "react-redux";
import { supShowLogin } from "../../../redux/actions/authActions";

const ParallaxLogin = ({ onNavigate, isPopupOpen, setIsPopupOpen }) => {
  const dispatch = useDispatch();
  const supShowLoginPage = useSelector((state) => state.auth.supShowLogin);
  const [showSuperVisorLogin, setShowSuperVisorLogin] = useState(false);
  const [showSignUpPage, setShowSignUpPage] = useState(false);

  const openSuperVisorLogin = () => {
    setIsPopupOpen(true);
    setShowSuperVisorLogin(true);
    sessionStorage.setItem("pageClosing", "true");
  };
  const closeSuperVisorLogin = () => {
    setIsPopupOpen(false);
    setShowSuperVisorLogin(false);
  };
  useEffect(() => {
    if (supShowLoginPage) {
      setShowSuperVisorLogin(true);
    }
  }, [supShowLoginPage]);
  const openSignUpPage = () => {
    setIsPopupOpen(true);
    setShowSignUpPage(true);
    sessionStorage.setItem("pageClosing", "true");
  };
  const closeSignUpPage = () => {
    setIsPopupOpen(false);
    setShowSignUpPage(false);
  };
  return (
    <div className="Parallax-container">
      <div className="Parallax-Content">
        <div className="Parallax-Text">
          {" "}
          FROM CONCEPT TO LICENSING, WE GOT YOUR BACK.
        </div>
      </div>
      <div className="Parallax-bottom">
        <div className="Parallax-logo-nav">
          <div className="Parallax-logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="Parallax-nav-group">
            <div className="parallav-nav-content">
              <div onClick={() => onNavigate("about")}>About SML</div>
              <div onClick={() => onNavigate("howto")}>How it works</div>
              <div onClick={() => onNavigate("pricing")}>Pricing</div>
              <div onClick={() => onNavigate("footer")}>Supervisor</div>
            </div>
            <div className="parallav-nav-content">
              <div onClick={() => onNavigate("faq")}>FAQ</div>
              <div>
                <a
                  href="/terms"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Terms
                </a>
              </div>
              <div>
                <a
                  href="/privacy-policy"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="parallax-btn-group">
          <button className="parallax-btn" onClick={() => {}}>
            Get Started
          </button>
          <button className="parallax-btn" onClick={openSuperVisorLogin}>
            Supervisor Login
          </button>
        </div>
      </div>
      <div className="parallax-btn-group-mobile">
        <button className="parallax-btn" onClick={openSignUpPage}>
          Get Started
        </button>
      </div>
      {showSuperVisorLogin && (
        <SupervisionLogin onClose={closeSuperVisorLogin} />
      )}
      {showSignUpPage && <SignUpPage onClose={closeSignUpPage} />}
    </div>
  );
};

export default ParallaxLogin;
