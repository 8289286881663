import axios from "axios";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
//Function to login
export const login = async ({ email, password, profile_type }) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, {
      email,
      password,
      profile_type,
    });
    const token = response.data.access_token;
    const refresh_token = response.data.refresh_token;
    const expiresIn = `${response.data.expires_in}`;
    const isMaster = response.data.profile.is_master;
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refresh_token);
    const expirationTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem("isMaster", isMaster);
    localStorage.setItem("token_expiration", expirationTime);
    localStorage.setItem("profile_type", response.data.profile_type);
    if (response.status === 200) return response.data;
    throw new Error(response.data.message || "Login issue");
  } catch (error) {
    console.log(error);
    throw error;
  }
};
