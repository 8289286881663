import React, { useEffect } from "react";
import "./MusicPartner.css";
import checkbox from "../../../assets/Images/checkboxIcon.svg";
import apps from "../../../assets/Images/apps.svg";

function MusicPartner() {
  useEffect(() => {
    const featureItems = document.querySelectorAll(".feature-item");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          entry.target.style.transitionDelay = `${1 + index * 0.6}s`;
          entry.target.classList.add("visible");
          observer.unobserve(entry.target);
        }
      });
    });

    featureItems.forEach((item) => {
      observer.observe(item);
    });
  }, []);

  return (
    <div className="section">
      <main className="main-section">
        <h1>WE ARE YOUR SYNC PARTNERS</h1>
        <p>
          We work with you from start to finish to ensure your music is sync
          ready with feedback from working music supervisors.
        </p>
        <button className="trial-button">Get Started</button>
      </main>
      <div className="scroll-container">
        <div className="logos">
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
          <img src={apps} alt="frame" />
        </div>
      </div>
      <div className="features">
        <div className="feature-item feature-item1">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Track feedback
        </div>
        <div className="feature-item feature-item2">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Sync certification
        </div>
        <div className="feature-item feature-item3">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Distribution to networks
        </div>
        <div className="feature-item feature-item4">
          <img
            style={{ marginRight: 8 }}
            width={28}
            height={28}
            src={checkbox}
            alt="Checkbox"
          />
          Career strategy collaboration
        </div>
      </div>
    </div>
  );
}

export default MusicPartner;
