import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
//Function to Getfeedback
export const getFeedbackApi = async (feedbackId) => {
  try {
    const accessToken = localStorage.getItem("token");
    // if (!accessToken) {
    //   console.error("No token found. Please log in.");
    //   return;
    // }
    const url = `${BASE_URL}/feedback/track/${feedbackId}`;
    const config = {
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.get(url, config);
    if (response.status !== 200) {
      console.error("Failed to post feedback. Status code:", response.status);
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};
