import { jwtDecode } from "jwt-decode";
import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
export const addCommentApi = async ({
  trackId,
  startTime,
  endTime,
  comment,
}) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const decodedToken = jwtDecode(accessToken);
    const supervisorId = decodedToken["custom:supervisor_id"];
    if (!supervisorId) {
      console.error("No supervisor ID found in the token.");
      return;
    }
    let data = new FormData();
    data.append("supervisor_id", supervisorId);
    data.append("track_id", trackId);
    data.append("start_time", startTime);
    data.append("end_time", endTime);
    data.append("comment", comment);
    const url = `${BASE_URL}/comments`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.post(url, data, config);

    if (response.status !== 200) {
      console.error(
        "Failed to submit the comment. Status code:",
        response.status
      );
    }
    return response.data;
  } catch (error) {
    console.log("Error message in submitting new comment", error.message);
  }
};

export const getAllCommentsApi = async (trackId) => {
  try {
    const accessToken = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await api.get(
      `${BASE_URL}/comments/track/${trackId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching single track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteCommentApi = async (commentId) => {
  try {
    const accessToken = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await api.delete(
      `${BASE_URL}/comments/${commentId}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching single track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateCommentApi = async ({ commentId, comment }) => {
  try {
    const accessToken = localStorage.getItem("token");

    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }

    const decodedToken = jwtDecode(accessToken);
    const supervisorId = decodedToken["custom:supervisor_id"]; // Extract supervisor ID from the token

    if (!supervisorId) {
      console.error("No supervisor ID found in the token.");
      return;
    }

    let data = new FormData();
    data.append("comment", comment);

    const url = `${BASE_URL}/comments/${commentId}`;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.put(url, data, config);

    if (response.status !== 200) {
      console.error(
        "Failed to submit the comment. Status code:",
        response.status
      );
    }
    return response.data;
  } catch (error) {
    console.log("Error message in submitting new comment", error.message);
  }
};
