import React from "react";
import "./getFeedbackExpert.css";
import getFeedbackExpert from "../../../assets/Images/getFeedbackExpert.svg";

const GetFeedbackExpert = () => {
  return (
    <div className="getFeedbackExpert-wrapper">
      <div className="getFeedbackExpert-rectangle">
        <div className="getFeedbackExpert-group">
          <img
            src={getFeedbackExpert}
            className="getFeedbackExpert-img"
            alt="Get Feedback Expert"
          />
          <div className="getFeedbackExpert-container">
            <button className="getFeedbackExpert-btn">Step 2</button>
            <div className="getFeedbackExpert-title">Get Expert Feedback.</div>
            <div className="getFeedbackExpert-content subtitle3">
              Receive a thorough review and a full, detailed evaluation from our
              music and sound experts. We'll give you specific notes from our
              producers and music supervisors on lyrical content, production
              quality and arrangement.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetFeedbackExpert;
