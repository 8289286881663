import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
// Mail Confirm API Endpoint
export const confirmMailApi= async (data) => {
  try {
    console.log(data);
    const newData = {
        email : data.mail,
        confirmation_code : data.code
    };
    const url = `${BASE_URL}/auth/confirm-email`;
    let config = {
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await api.post(url, newData, config);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};
