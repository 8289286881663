import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
const isTokenExpired = () => {
  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
  return !tokenExpiryTime || Date.now() > tokenExpiryTime;
};
const getValidToken = async () => {
  if (isTokenExpired()) {
    throw new Error("Token expired. Please log in again.");
  }
  return localStorage.getItem("accessToken");
};
export const deleteTrackApi = async (trackId) => {
  try {
    const token = await getValidToken();
    const response = await api.delete(`${BASE_URL}/track/${trackId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting track",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
