import {
  GET_FEEDBACK_FAILURE,
  GET_FEEDBACK_LOADING,
  GET_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE,
  POST_FEEDBACK_LOADING,
  POST_FEEDBACK_SUCCESS,
  PUT_FEEDBACK_MS_FAILURE,
  PUT_FEEDBACK_MS_LOADING,
  PUT_FEEDBACK_MS_SUCCESS,
  PUT_FINAL_FEEDBACK_MS_FAILURE,
  PUT_FINAL_FEEDBACK_MS_LOADING,
  PUT_FINAL_FEEDBACK_MS_SUCCESS,
  RESET_GET_FEEDBACK,
  RESET_POST_FEEDBACK_ASSIGNED,
  RESET_PUT_FEEDBACK_MS_ASSIGNED,
  RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED,
} from "../actions/actionTypes";

const initialState = {
  isError: false,
  isLoading: false,
  result: [],
  error: undefined,
};

export const getFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        feedback: action.payload, 
        error: undefined,
      };
    case GET_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RESET_GET_FEEDBACK: {
      return {
        isError: false,
        isLoading: false,
        result: [],
        error: undefined,
      };
    }
    default:
      return state;
  }
};
export const postFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_FEEDBACK_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assigned: action.payload, 
        error: undefined,
      };
    case POST_FEEDBACK_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RESET_POST_FEEDBACK_ASSIGNED:
      const { assigned, ...rest } = state;
      return {
        ...rest,
      };
    default:
      return state;
  }
};

export const putFeedbackMsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_FEEDBACK_MS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case PUT_FEEDBACK_MS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assigned: action.payload, 
        error: undefined,
      };
    case PUT_FEEDBACK_MS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RESET_PUT_FEEDBACK_MS_ASSIGNED:
      const { assigned, ...rest } = state;
      return {
        ...rest,
      };
    default:
      return state;
  }
};
export const putFinalFeedbackMsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_FINAL_FEEDBACK_MS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: undefined,
      };
    case PUT_FINAL_FEEDBACK_MS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        assigned: action.payload, 
        error: undefined,
      };
    case PUT_FINAL_FEEDBACK_MS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case RESET_PUT_FINAL_FEEDBACK_MS_ASSIGNED:
      const { assigned, ...rest } = state;
      return {
        ...rest,
      };
    default:
      return state;
  }
};
