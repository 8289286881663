import React from "react";
import Styles from "./TracksViewStyles.module.css";
import DefaultTrackImage from "../../../assets/Images/TrackDefaultImg.svg";

export default function TracksViewHeader({
  headerLabel,
  tracks,
  headerLabelIcon,
}) {
  return (
    <div className={Styles.tracksViewHeaderContainer}>
      <TracksOverviewImage />
      <div className={Styles.tracksViewLabelContainer}>
        <h2>
          {headerLabel}{" "}
          {headerLabelIcon && <img src={headerLabelIcon} alt={headerLabel} />}
        </h2>
        <h4>{Array.isArray(tracks) ? tracks.length : 0} Tracks</h4>
      </div>
    </div>
  );
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const TracksOverviewImage = ({ tracks }) => {
  let overviewimages = [
    getRandomInt(tracks?.length),
    getRandomInt(tracks?.length),
    getRandomInt(tracks?.length),
    getRandomInt(tracks?.length),
  ].map((item) =>
    tracks?.[item].trackImgSrc ? tracks?.[item]?.trackImgSrc : DefaultTrackImage
  );

  return (
    <div className={Styles.tracksOverviewImageContainer}>
      {overviewimages?.map((item, index) => {
        return (
          <div className={Styles.tracksOverviewImageItem} key={index}>
            <img
              src={item}
              alt={"track icon " + index}
              height={"100%"}
              width={"100%"}
            />
          </div>
        );
      })}
    </div>
  );
};
