import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev"; // Use the proxy base URL

// Function to fetch artist data
export const getSingleArtistApi = async (artistId) => {
  try {
    const accessToken = localStorage.getItem("token");
    const url = `${BASE_URL}/profiles/artists/${artistId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`, //
        "Content-Type": "application/json",
      },
    };

    const response = await api.get(url, config);
    return response?.data;
  } catch (error) {
    console.error(
      "Error updating",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
