import { all } from "redux-saga/effects";
import authSaga from "./authSaga";
import searchSaga from "./searchSaga";
import trackStatsSaga from "./trackStatsSaga";
import smlSaga from "./smlSagas";
import assignSupervisorSaga from "./assignSupervisorSaga";
import {
  postFeedbackSaga,
  putFinalFeedbackMsSaga,
  putFeedbackMsSaga,
  getFeedbackSaga,
} from "./postFeedbackSaga";
import { downloadTrackSaga } from "./downloadTrackSaga";
import { getSingleArtistSaga } from "./artistSaga";
import supervisorSaga from "./supervisorSaga";
import paymentSaga from "./paymentSaga";
import forgetPasswordSaga from "./forgetPasswordSaga";
import confirmMailSaga from "./confirmMailSaga";
export default function* rootSaga() {
  yield all([
    authSaga(),
    trackStatsSaga(),
    smlSaga(),
    assignSupervisorSaga(),
    postFeedbackSaga(),
    putFeedbackMsSaga(),
    putFinalFeedbackMsSaga(),
    downloadTrackSaga(),
    getSingleArtistSaga(),
    getFeedbackSaga(),
    supervisorSaga(),
    paymentSaga(),
    forgetPasswordSaga(),
    confirmMailSaga()
  ]);
}
