import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
// Function to create subscriptions
export const createSubApi = async (data) => {
  try {
    console.log(data);
    const newData = {
      profileId: data.id,
      email: data.email,
      priceId: data.priceId,
      paymentMethodId: data.methodId,
    };
    const accessToken = data.token;
    const url = `${BASE_URL}/subscriptions`;
    let config = {
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await api.post(url, newData, config);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};
