import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
export const postTrackApi = async ({
  audio,
  trackName,
  genreName,
  selectedRoles,
  workStatus,
  syncedBefore,
  samplesUsed,
  sampleSources,
  feedbackOptions,
  targetOptions,
  additionalInfo,
}) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No token found. Please log in.");
      return;
    }
    const decodedToken = jwtDecode(accessToken);
    const artistId = decodedToken["custom:artist_id"];
    let data = new FormData();
    data.append("artist_id", artistId);
    data.append("title", trackName);
    data.append("genre", genreName);
    data.append("status", workStatus);
    data.append("roles", selectedRoles);
    data.append("work_status", workStatus);
    data.append("synced_before", syncedBefore);
    data.append("samples_used", samplesUsed);
    data.append("sample_sources", sampleSources);
    data.append("feedback_services", feedbackOptions);
    data.append("targets", targetOptions);
    data.append("additional_info", additionalInfo);
    data.append("file_name", audio.name);
    data.append("file_type", "audio/mpeg");
    const url = `${BASE_URL}/tracks/signed-url`;
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const res = await api.post(url, data, config);
    const uploadUrl = res.data.file_url;
    const track = res.data;
    let newConfig = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        "Content-Type": "audio/mpeg",
      },
    };
    const uploadResponse = await api.put(uploadUrl, audio, newConfig);
    return track;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Error request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);
    throw error;
  }
};
