import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./Header.module.css";
import UserMobileIcon from "../../../assets/Icons/user-mobile.svg";
import ChevronUp from "../../../assets/Icons/chevron-up.svg";
import ChevronDown from "../../../assets/Images/chevron-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../../redux/actions/authActions";
import { RESET_ARTIST } from "../../../redux/actions/smlActions";
import { googleLogout } from "@react-oauth/google";
import { RESET_ASSIGNED_SUPERVISOR } from "../../../redux/actions/actionTypes";
export default function ProfileDropdown({ name }) {
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const artists = useSelector((store) => store.sml?.allArtistFetched?.artists);
  const username = artists?.name || "";

  const toggleLogout = () => {
    setShowLogout(!showLogout);
  };

  const logout = () => {
    localStorage.clear();
    dispatch(logoutRequest());
    dispatch({ type: RESET_ASSIGNED_SUPERVISOR });
    dispatch({ type: RESET_ARTIST });
    googleLogout();
    navigate("/");
  };

  return (
    <div className={Styles.profileDropdown}>
      <button onClick={toggleLogout} className={Styles.profileButton}>
        <img src={UserMobileIcon} alt="User" />
        {username}
        <img src={showLogout ? ChevronDown : ChevronUp} alt="Chevron" />
      </button>
      {showLogout && <Logout onLogout={logout} />}
    </div>
  );
}

function Logout({ onLogout }) {
  return (
    <div className={Styles.logoutCard}>
      <button onClick={onLogout}>Logout</button>
    </div>
  );
}
