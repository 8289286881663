import { call, put, takeLatest } from 'redux-saga/effects';
import { postFeedbackApi } from '../../api/feedback/postFeedback';
import { putFeedbackMsApi } from '../../api/feedback/putFeedback';
import {
  getFeedbackFailure,
  getFeedbackLoading,
  getFeedbackSuccess,
  postFeedbackFailure,
  postFeedbackSuccess,
  putFeedbackMsFailure,
  putFeedbackMsSuccess,
  putFinalFeedbackMsFailure,
  putFinalFeedbackMsSuccess,
} from '../actions/postFeedbackActions';
import {
  GET_FEEDBACK_LOADING,
  GET_FEEDBACK_REQUEST,
  POST_FEEDBACK_LOADING,
  POST_FEEDBACK_REQUEST,
  PUT_FEEDBACK_MS_LOADING,
  PUT_FEEDBACK_MS_REQUEST,
  PUT_FINAL_FEEDBACK_MS_LOADING,
  PUT_FINAL_FEEDBACK_MS_REQUEST,
} from '../actions/actionTypes';
import { putFinalFeedbackMsApi } from '../../api/feedback/putFinalFeedback';
import { getFeedbackApi } from '../../api/feedback/getFeedbackApi';

function* getFeedback(action) {
  try {
    const feedbackId = action.payload;
    yield put({ type: GET_FEEDBACK_LOADING });
    const feedback = yield call(getFeedbackApi, feedbackId);
    yield put(getFeedbackSuccess(feedback));
  } catch (error) {
    yield put(getFeedbackFailure(error.message));
  }
}
function* postFeedback(action) {
  try {
    const data = action.payload;
    yield put({ type: POST_FEEDBACK_LOADING });
    const result = yield call(postFeedbackApi, data);
    yield put(postFeedbackSuccess(result));
  } catch (error) {
    yield put(postFeedbackFailure(error.message));
  }
}

function* putFeedbackMs(action) {
  try {
    const data = action.payload;
    yield put({ type: PUT_FEEDBACK_MS_LOADING });
    const result = yield call(putFeedbackMsApi, data);
    yield put(putFeedbackMsSuccess(result));
  } catch (error) {
    yield put(putFeedbackMsFailure(error.message));
  }
}
function* putFinalFeedbackMs(action) {
  try {
    const data = action.payload;
    yield put({ type: PUT_FINAL_FEEDBACK_MS_LOADING });
    const result = yield call(putFinalFeedbackMsApi, data); 
    yield put(putFinalFeedbackMsSuccess(result));
  } catch (error) {
    yield put(putFinalFeedbackMsFailure(error.message));
  }
}

export function* postFeedbackSaga() {
  yield takeLatest(POST_FEEDBACK_REQUEST, postFeedback);
}

export function* putFeedbackMsSaga() {
  yield takeLatest(PUT_FEEDBACK_MS_REQUEST, putFeedbackMs);
}

export function* putFinalFeedbackMsSaga() {
  yield takeLatest(PUT_FINAL_FEEDBACK_MS_REQUEST, putFinalFeedbackMs);
}
export function* getFeedbackSaga() {
  yield takeLatest(GET_FEEDBACK_REQUEST, getFeedback);
}
