import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SUPHeader from "../../components/Shared/Header/SupHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RateSlider from "../../components/UI/RateBar/RateBar";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import { RESET_GET_FEEDBACK } from "../../redux/actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeft from "../../assets/Icons/arrow-left.svg";
import "./TrackEvaluation.css";
import { getFeedbackRequest } from "../../redux/actions";
import Union from "../../assets/Images/Union.png";

const showingFields = [
  "Production",
  "Vocals",
  "Lyrics",
  "Dynamics",
  "Build",
  "Editor Hit Points",
  "Stop-downs",
  "Back End",
];
const fields = [
  "production",
  "vocals",
  "lyrics",
  "dynamics",
  "build",
  "editor_hit_points",
  "stop_downs",
  "back_end",
];

const TrackEvaluatedResults = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackId } = useParams();
  const status = location.state?.status;
  const fromUserPortal = location.state?.fromUserPortal;
  const feedback = useSelector((state) => state.getFeedback?.feedback);

  useEffect(() => {
    dispatch({ type: RESET_GET_FEEDBACK });
    dispatch(getFeedbackRequest(trackId));
  }, [status, fromUserPortal, dispatch]);

  const [rating, setRatings] = useState({});
  const [syncPotential, setSyncPotential] = useState(true);
  const [suggestedTargets, setSuggestedTargets] = useState([]);
  const [notes, setNotes] = useState("");
  const [downloaded, setDownloaded] = useState(false);
  const [finalNote, setFinalNote] = useState("");
  const [reviewedStatus, setReviewedStatus] = useState(status);
  useEffect(() => {
    if (feedback) {
      setDownloaded(
        feedback[0]?.master_feedback?.download_track ||
          feedback[0]?.supervisor_feedback?.download_track
      );
      setRatings(
        feedback[0]?.master_feedback?.ratings ||
          feedback[0]?.supervisor_feedback?.ratings
      );
      setSyncPotential(
        feedback[0]?.master_feedback?.sync_potential ||
          feedback[0]?.supervisor_feedback?.sync_potential
      );
      setSuggestedTargets(
        feedback[0]?.supervisor_feedback?.suggested_targets || []
      );
      setNotes(feedback[0]?.supervisor_feedback?.notes);
      setFinalNote(feedback[0]?.master_feedback?.final_feedback);
      setReviewedStatus(feedback[0].reviewed.status);
    }
  }, [feedback]);

  const handleChangePages = () => {
    navigate(-1);
  };

  return (
    <div
      className="vh-100"
      style={{ overflow: "auto", color: "#333", backgroundColor: "#f8f9fa" }}
    >
      <div style={{ backgroundColor: "#f8f9fa" }}>
        {/* {!props.fromFeedback && <SUPHeader />} */}
        {/* <div className="track-bg-main-div-feedback mt-5">
          <Row className="mb-5">
            <Col>
              <div className="trackEvalHeaderBox">
                <img
                  src={ArrowLeft}
                  alt="Go Back"
                  onClick={handleChangePages}
                />
                <SMLLabel style="title1" value="Track Evaluation" />
              </div>
            </Col>
          </Row>
        </div> */}

        <div
          className="track-bg-main-div-feedback track-rate-container mt-3 mb-1"
          style={{ backgroundColor: "#ffffff" }}
        >
          <Row className="mb-5">
            <Col>
              <SMLLabel style="title1" value="Track Ratings" />
            </Col>
          </Row>
          {fields.map((field, index) => (
            <div key={index} className="my-4">
              <Row>
                <Col>
                  <SMLLabel
                    value={showingFields[index]}
                    style="rating-title2"
                  />
                </Col>
              </Row>
              <div className="ratebar">
                <Col className="track-rate-slider-div">
                  <Row className="d-flex align-items-center">
                    <Col className=" ratesliderBar">
                      {rating[field] !== undefined && (
                        <RateSlider value={rating[field]} disabled={true} />
                      )}
                    </Col>
                    <Col
                      style={{
                        marginBottom: "2rem",
                      }}
                      className="col-1"
                    >
                      <SMLLabel value={rating[field]} style="rating-title2" />
                    </Col>
                    <Col
                      className="col-1"
                      style={{
                        marginBottom: "2rem",
                      }}
                    >
                      <SMLLabel
                        value={rating[field] === 0 ? "N/A" : ""}
                        style={rating[field] === 0 ? "black" : {}}
                      />
                    </Col>
                  </Row>
                </Col>

                {rating?.[`${field}_notes`] &&
                  feedback &&
                  feedback.length > 0 && (
                    <Row>
                      <Col>
                        <div className="notes-line">
                          <span style={{ fontWeight: "bold", color: "black" }}>
                            Note:&nbsp;
                          </span>
                          <div style={{ marginTop: "0.2rem" }}>
                            <SMLLabel
                              value={`${
                                feedback[0]?.master_feedback?.ratings?.[
                                  `${field}_notes`
                                ] ||
                                feedback[0]?.supervisor_feedback?.ratings?.[
                                  `${field}_notes`
                                ] ||
                                ""
                              }`}
                              style="sub-subtitle2"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
              </div>
            </div>
          ))}
        </div>

        <div className="track-bg-not-main-div track-rate-container mt-3 mb-3">
          <Row>
            <Col>
              <SMLLabel style="title1" value="Supervisor's Evaluation" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="sub-subtitle2">
                The information in this section will not be seen by the artist.
                However, it will be archived for future reference.
              </span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="trackEvalBtnBox">
              <SMLLabel
                value="Does this track have potential for sync?"
                style="title2"
              />
              <SMLLabel
                style="sub-subtitle2"
                value={syncPotential ? "Yes" : "No"}
              />
            </Col>
          </Row>
          {suggestedTargets && suggestedTargets.length > 0 && (
            <>
              <Row>
                <Col className="my-3">
                  <SMLLabel
                    value="Suggested targets for track placement opportunities"
                    style="title2"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {suggestedTargets.map((target, index) => (
                    <SMLOptionQuestion
                      key={index}
                      type="Button"
                      style="SMLSecondaryButtonSelected"
                      value={target}
                      disabled={true}
                    />
                  ))}
                </Col>
              </Row>
            </>
          )}
          {status !== "final_feedback_submitted" && (
            <>
              <Row>
                <Col className="my-3">
                  <SMLLabel value="Supervisor Notes" style="title2" />
                </Col>
              </Row>
              <Row>
                <p>{notes}</p>
              </Row>
            </>
          )}
          {downloaded && (
            <Row className="mt-3">
              <Col className="trackEvalBtnBox">
                <img src={Union} className="" />
                <SMLLabel value="Track Certified" style="title2" />
              </Col>
            </Row>
          )}
        </div>
        {reviewedStatus !== "in progress" && (
          <div
            className="track-bg-main-div track-rate-container py-5"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="mt-2">
              <SMLLabel value="Final Note To Artist" style="Mainheader" />
            </div>
            <div className="d-flex align-items-start gap-2">
              <div className="my-1">
                <SMLLabel value="Notes:  " style="title2" />
              </div>
              <div className="my-2" style={{ width: "85%" }}>
                <SMLLabel value={finalNote} style="title3" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackEvaluatedResults;
