import { jwtDecode } from "jwt-decode";
import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";

export const getTrack = async ({ trackStatus, type }) => {
  try {
    const accessToken = localStorage.getItem("token");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    const decodedToken = jwtDecode(accessToken);
    const supervisorId = decodedToken["custom:supervisor_id"];
    const isMaster = localStorage.getItem("isMaster");
    let Id = null;
    let url = null;
    if (type === "artist_id") {
      if (trackStatus === "submitted") {
        url = `${BASE_URL}/tracks?trackStatus=${trackStatus}&sortedBy=created_at&sortOrder=desc`;
      } else if (trackStatus === "in_review" || "final_feedback_received") {
        url = `${BASE_URL}/tracks?trackStatus=${trackStatus}&sortedBy=updated_at&sortOrder=desc`;
      }
    } else {
      if (trackStatus === "submitted" && isMaster === "true") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=${trackStatus}&limit=20sortedBy=created_at&sortOrder=desc&assignedSupervisorId=null`;
      } else if (trackStatus === "submitted" && isMaster === "false") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=${trackStatus}&limit=20sortedBy=created_at&sortOrder=desc&assignedSupervisorId=${supervisorId}`;
      } else if (trackStatus === "Assigned") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=submitted&limit=20sortedBy=created_at&sortOrder=desc&assignedSupervisorId=!${supervisorId}`;
      } else if (trackStatus === "in_review") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=in_review&limit=20sortedBy=created_at&sortOrder=desc&assignedSupervisorId=${supervisorId}`;
      } else if (trackStatus === "feedback_submitted") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=feedback_submitted&limit=20sortedBy=created_at&sortOrder=desc&assignedSupervisorId=${supervisorId}`;
      } else if (trackStatus === "Pending") {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=feedback_submitted&limit=20sortedBy=updated_at&sortOrder=desc&assignedSupervisorId=!${supervisorId}`;
      } else if (
        trackStatus === "final_feedback_submitted" &&
        isMaster === "true"
      ) {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=${trackStatus}&limit=20sortedBy=updated_at&sortOrder=desc&assignedSupervisorId=all   `;
      } else if (
        trackStatus === "final_feedback_submitted" &&
        isMaster === "false"
      ) {
        url = `${BASE_URL}/tracks/supervisor?trackStatus=${trackStatus}&limit=20sortedBy=updated_at&sortOrder=desc&assignedSupervisorId=${supervisorId}`;
      }
    }
    let config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching tracks:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
