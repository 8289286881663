import React, { useEffect, useState } from "react";
import "./ParallaxPlan.css";
import "../../../styles/Fonts.scss";
import plans from "./ParallaxPlan.json";
import checkBox from "../../../assets/Images/checkboxIcon.svg";
import SMLToggleButton from "../../Shared/SMLToggleButton/SMLToggleButton";
import bestValue from "../../../assets/Images/popular.svg";
import { useDispatch, useSelector } from "react-redux";
import { getPlansRequest } from "../../../redux/actions";
import SignUpPage from "../../../pages/SignUpPage/SignUpPage";

const ParallaxPlan = () => {
  const dispatch = useDispatch();
  const [sendIndex, setSendIndex] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(sendIndex);
  const [selectedPlanData, setSelectedPlanData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  useEffect(() => {
    dispatch(getPlansRequest()); // Fetch available plans from endpoint
  }, []);

  const plans1 = useSelector((state) => state.payment.getPlansReducer?.plans);

  //logic to match plan in json to the plan price in endpoint
  const findMatchingPlan = (plan) => {
    const priceId = isYearly ? plan.YearlyId : plan.monthlyId;

    return plans1.find((p1) => p1.id === priceId);
  };

  const handleCardClick = (index) => {
    setSelectedPlan(index);
    setSendIndex(index);
    setSelectedPlanData(findMatchingPlan(plans[index]));
    setShowPopup(true);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleIndex = (index, isYearly) => {
    setSelectedPlan(index);
    setIsYearly(isYearly);
  };
  return (
    <>
      {showPopup && (
        <SignUpPage
          onClose={handleClosePopup}
          selectedPlan={selectedPlanData}
          planIndex={sendIndex}
          isYearly={isYearly}
          fromHome={true}
          handleIndex={handleIndex}
        />
      )}
      <div className="parallaxPlan-container">
        <div className="parallaxPlan-main">
          <div className="mains-container">
            <h1 className="heading pricing-title">
              We've got the right plan for you.
            </h1>
            <div className="toggle-buttons" onClick={stopPropagation}>
              <label className="title2">Monthly</label>
              <SMLToggleButton
                value={isYearly}
                checked={isYearly}
                onChange={() => {
                  setIsYearly(!isYearly);
                  setSelectedPlan(null);
                }}
              />
              <label className="title2">Yearly</label>
              <span className="title2">Save 20% over monthly</span>
            </div>
            <div className="plan-cards-container">
              {plans.map((plan, index) => (
                <div
                  key={index}
                  className={`plan-card ${
                    selectedPlan === index ? "selected-card" : ""
                  }`}
                >
                  {plan.bestValue && (
                    <img
                      src={bestValue}
                      alt="Best Value"
                      className="best-value-image"
                    />
                  )}
                  <div className="plan-card-content">
                    <h2 className="title1">{plan.name}</h2>
                    <div className="price-container">
                      <p className="plan-price">
                        {isYearly ? plan.yearlyPrice : plan.price}
                        <span className="plan-duration">
                          {isYearly ? "/year" : "/month"}
                        </span>
                      </p>
                    </div>
                    <div className="horizontal-line"></div>
                    <ul className="features-list">
                      {plan.features.map((feature, i) => {
                        if (typeof feature === "string") {
                          return (
                            <li key={i} className="features-item">
                              <img
                                src={checkBox}
                                alt="checkbox"
                                className="checkbox-icon"
                              />
                              <span className="feature-text">{feature}</span>
                            </li>
                          );
                        } else if (typeof feature === "object") {
                          const [title, nestedFeatures] =
                            Object.entries(feature)[0];
                          return (
                            <React.Fragment key={i}>
                              <li className="features-item">
                                <img
                                  src={checkBox}
                                  alt="checkbox"
                                  className="checkbox-icon"
                                />
                                <span className="feature-text">{title}</span>
                              </li>
                              {nestedFeatures.map((nestedFeature, j) => (
                                <li
                                  key={`${i}-${j}`}
                                  className="features-item nested-feature-item"
                                >
                                  <span className="bullet-icon">•</span>
                                  <span className="feature-text">
                                    {nestedFeature}
                                  </span>
                                </li>
                              ))}
                            </React.Fragment>
                          );
                        }
                      })}
                    </ul>
                  </div>
                  <div onClick={() => handleCardClick(index)}>
                    <button className={`plan-button plan-button-${index}`}>
                      {selectedPlan === index ? "Selected Plan" : plan.button}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParallaxPlan;
