import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_LOADING,
  RESET_SEARCH,
} from '../actions/actionTypes';

const initialState = {
  searchedResult: {
    result: [],
    isLoading: false,
    isError: false,
    error: undefined,
  },
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_LOADING:
      return {
        ...state,
        searchedResult: {
          ...state.searchedResult,
          isLoading: true,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchedResult: {
          result: action.result,
          isLoading: false,
          isError: false,
          error: undefined,
        },
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        searchedResult: {
          result: [],
          isLoading: false,
          isError: true,
          error: action.error,
        },
      };
    case RESET_SEARCH:
      return {
        ...state,
        searchedResult: initialState.searchedResult,
      };
    default:
      return state;
  }
};
