import {
  CREATE_SUB_REQUEST,
  GET_CLIENT_SECRET_REQUEST,
  GET_PB_KEY_FAILURE,
  GET_PB_KEY_LOADING,
  GET_PB_KEY_REQUEST,
  GET_PB_KEY_SUCCESS,
  GET_PLANS_REQUEST,
} from "./actionTypes";

export const getPbKeyRequest = () => {
  return {
    type: GET_PB_KEY_REQUEST,
  };
};

export const getPlansRequest = () => {
  return {
    type: GET_PLANS_REQUEST,
  };
};

export const getClientSecret = ({ amount, currency }) => {
  return {
    type: GET_CLIENT_SECRET_REQUEST,
    payload: { amount, currency },
  };
};
export const createSubReqeuest = ({ id, email, priceId, methodId, token }) => {
  return {
    type: CREATE_SUB_REQUEST,
    payload: { id, email, priceId, methodId, token },
  };
};
