import axios from "axios";

const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
//Function to Signup
export const signup = async ({
  fullName,
  email,
  password,
  profileType,
  roles,
  experienceWithSync,
  smlLeadInfo,
  personalLinks,
  selectedName,
  musicExperienceInfom,
  guidanceNeededInfo,
  membPlan,
}) => {
  try {
    let obj = {
      email: email,
      password: password,
      full_name: fullName,
      // profile_type: profileType,
      profile_type: "artist",
      profile: {
        role: roles,
        experience_with_sync: experienceWithSync,
        sml_lead_info: smlLeadInfo !== "" ? smlLeadInfo : selectedName,
        personal_links: personalLinks,
        music_experience_info: musicExperienceInfom,
        guidance_needed_info: guidanceNeededInfo,
        auth_provider: "email",
        membership_plan: membPlan,
      },
    };
    console.log(obj)
    const response = await axios.post(`${BASE_URL}/auth/register`, obj);
    if (response.status === 200) return response.data;
    throw new Error(response.data.message || "Signup failed");
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

export const supSignupApi = async ({
  email,
  password,
  fullName,
  profileType,
  name,
  company,
  profession,
  venmo,
  zelle,
}) => {
  try {
    const obj = {
      email: email,
      password: password,
      full_name: fullName,
      profile_type: profileType,
      profile: {
        name: name,
        company: company,
        profession: profession,
        payment_info: {
          venmo: venmo,
          zelle: zelle,
        },
      },
    };

    const response = await axios.post(`${BASE_URL}/auth/register`, obj);
    return response.data;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};
