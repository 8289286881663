import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "../SMLTextArea/SMLTextArea.css";

const SMLTextArea = (props) => {
  return (
    <textarea
      value={props.value}
      onChange={props.onChange}
      className="SMLTextarea"
    ></textarea>
  );
};

export default SMLTextArea;
