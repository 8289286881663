import React from "react";
import "./uploadYourTracks.css";
import musicComposer from "../../../assets/Images/musicComposer.svg";

const UploadYourTracks = () => {
  return (
    <div className="uploadTracks-wrapper">
      <div className="uploadTracks-rectangle">
        <div className="uploadTracks-group">
          <img
            src={musicComposer}
            className="uploadTracks-img"
            alt="Music Composer"
          />
          <div className="uploadTracks-container">
            <button className="uploadTracks-btn">Step 1</button>
            <div className="uploadTracks-title">Upload Your Tracks.</div>
            <div className="uploadTracks-content subtitle3">
              Upload your demos or masters. We will share the tracks to selected
              music supervisors and sync experts to provide feedback. If your
              tracks are already in excellent shape, we are here to get you to
              next steps for pitching.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadYourTracks;
