import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SMLButton from "../../../components/Shared/SMLButton/SMLButton";
import SMLButtonWithIcon from "../../../components/Shared/SMLButtonWithIcon/SMLButtonWithIcon";
import SMLCheckbox from "../../../components/Shared/SMLCheckbox/SMLCheckbox";
import SMLLabel from "../../../components/Shared/SMLLabel/SMLLabel";
import SMLTextBox from "../../../components/Shared/SMLTextBox/SMLTextBox";
import "../../../styles/Fonts.scss";
import "../../SignUpPage/SignUpPage.css";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import Backbutton from "../../../components/Shared/BackButton/BackButton";
import TermsAndConditions from "../../TermsAndConditions/index";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { APPCONFIG } from "../../../config/APPConfig";
import closeIcon from "../../../assets/Images/closeIcon.svg";
const ContactInformation = (props) => {
  const [fullName, setFullName] = useState(props.contactData.fullName);
  const [email, setEmail] = useState(props.contactData.email);
  const [createPassword, setCreatePassword] = useState(
    props.contactData.createPassword
  );
  const [verifyPassword, setVerifyPassword] = useState(
    props.contactData.verifyPassword
  );
  const [agreeConditions, setAgreeConditions] = useState(
    props.contactData.agreeConditions
  );
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [createPasswordError, setCreatePasswordError] = useState("");
  const [verifyPasswordError, setVerifyPasswordError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validatePassword = (password) => {
    const isValid =
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[^\w\s]/.test(password) &&
      !/\s/.test(password);

    if (!isValid) {
      return "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, one special character, and must not contain spaces.";
    }

    return null;
  };

  const handleContactInformation = (e) => {
    e.preventDefault();
    if (!fullName.trim()) {
      setFullNameError("Full Name is required");
      return;
    } else {
      setFullNameError("");
    }

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    } else {
      setEmailError("");
    }
    // Validate create password
    if (!createPassword.trim()) {
      setCreatePasswordError("Create Password is required");
      return;
    } else {
      const passwordError = validatePassword(createPassword);
      if (passwordError) {
        setCreatePasswordError(passwordError);
        return;
      } else {
        setCreatePasswordError("");
      }
    }
    // Validate verify password
    if (!verifyPassword.trim()) {
      setVerifyPasswordError("Verify Password is required");
      return;
    } else {
      setVerifyPasswordError("");
    }

    // Check if passwords match
    if (createPassword !== verifyPassword) {
      setVerifyPasswordError("Password does not match");
      return;
    } else {
      setVerifyPasswordError("");
    }

    const contactData = {
      fullName,
      email,
      createPassword,
      verifyPassword,
      agreeConditions,
    };

    props.onContactInformationClick(contactData);
  };

  const handleLoginClick = () => {
    props.goToLogin();
  };

  const handleFacebookSignup = (credentialResponse) => {
    const token = {
      credential: credentialResponse.credential,
      provider: "facebook",
      profile_type: "artist",
    };
    if (token) {
      props.onContactInformationClick(token);
    }
  };
  const handleGoogleSignup = (credentialResponse) => {
    const token = {
      credential: credentialResponse.credential,
      provider: "google",
      profile_type: "artist",
    };
    if (token) {
      props.onContactInformationClick(token);
    }
  };

  const signup = () => {
    const callbackUrl = `${window.location.origin}/login`;
    const googleClientId =
      "874371419611-9brm4hqjvlscomnub6dtr8b01r60ta44.apps.googleusercontent.com";
    const targetUrl = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${encodeURIComponent(
      callbackUrl
    )}&response_type=token%20id_token&client_id=${googleClientId}&scope=openid%20email%20profile`;
    window.location.href = targetUrl;
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleBackClick = () => {
    const contactData = {
      fullName,
      email,
      createPassword,
      verifyPassword,
      agreeConditions,
    };
    props.handleBack(contactData);
  };

  // const handleFullNameChange = (e) => {
  //   const value = e.target.value;
  //   const regex = /^[A-Za-z!@#$%^&*()_+|~=`{}\[\]:;\"'<>,.?/ -]*$/;

  //   if (regex.test(value)) {
  //     setFullName(value);
  //   }
  // };

  return (
    <div>
      <div className="signUp-progress-steps">
        <ProgressSteps step="1" />
      </div>
      <div className="signUp-close-div" onClick={props.closePopup}>
        <img src={closeIcon} style={{ cursor: "pointer" }} alt="Close" />
      </div>
      <div className="signUp-main">
        <div className="signUp-label-div">
          <SMLLabel value="Contact Information" style="Mainheader" />
        </div>
        <div className="error-box">
          {emailError && <SMLLabel style="button1 error" value={emailError} />}
          {createPasswordError && (
            <SMLLabel style="error" value={createPasswordError} />
          )}
          {verifyPasswordError && (
            <SMLLabel
              style="error"
              className="error"
              value={verifyPasswordError}
            />
          )}
        </div>
        <form className="signUp-grid" onSubmit={handleContactInformation}>
          <div className="signUp-content-left">
            <div className="signUp-controls">
              <div className="signUp-fields">
                <SMLLabel value="Full Name" style="title2"></SMLLabel>
                <SMLTextBox
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {fullNameError && (
                  <span className="button1 error">{fullNameError}</span>
                )}
              </div>
              <div className="signUp-fields">
                <SMLLabel value="Email" style="title2"></SMLLabel>
                <SMLTextBox
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="signUp-fields">
                <SMLLabel value="Create Password" style="title2"></SMLLabel>
                <div className="password-container">
                  <SMLTextBox
                    type={showCreatePassword ? "text" : "password"}
                    value={createPassword}
                    onChange={(e) => setCreatePassword(e.target.value)}
                  />
                  <span
                    className="toggle-password-visibility"
                    onClick={() => setShowCreatePassword(!showCreatePassword)}
                  >
                    {showCreatePassword ? (
                      <MdVisibility />
                    ) : (
                      <MdVisibilityOff />
                    )}
                  </span>
                </div>
              </div>
              <div className="signUp-fields">
                <SMLLabel value="Verify Password" style="title2"></SMLLabel>
                <div className="password-container">
                  <SMLTextBox
                    type={showVerifyPassword ? "text" : "password"}
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                  />
                  <span
                    className="toggle-password-visibility"
                    onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                  >
                    {showVerifyPassword ? (
                      <MdVisibility />
                    ) : (
                      <MdVisibilityOff />
                    )}
                  </span>
                </div>
              </div>
              <div className="signUp-fields">
                <div className="signUp-groupby">
                  <SMLCheckbox
                    checked={agreeConditions}
                    onChange={(e) => setAgreeConditions(e.target.checked)}
                  />
                  <div className="title2">
                    I accept all{" "}
                    <a className="link" onClick={openModal}>
                      terms and conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="signUp-content">
            <div className="signUp-controls-divider">
              <div className="divider"></div>
              <span>or</span>
              <div className="divider"></div>
            </div>
          </div>
          <div className="signUp-right-content">
            <div className="signUp-controls-right">
              <div className="provider-signup-controls">
                <div className="provider-btn">
                  <GoogleLogin
                    className="google-login-button"
                    onSuccess={(codeResponse) => {
                      handleGoogleSignup(codeResponse);
                    }}
                    onError={() => console.log("Login Failed")}
                    width="232"
                    setOneTap={false}
                  />
                </div>
                <div className="provider-btn">
                  <FacebookLogin
                    appId={APPCONFIG.FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    callback={handleFacebookSignup}
                    render={(renderProps) => (
                      <SMLButtonWithIcon
                        type="button"
                        value="Sign up with Facebook"
                        icon="Facebook"
                        onClick={() => {
                          renderProps.onClick();
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="signUp-fields12">
                <div className="signUp-groupby">
                  <SMLLabel value="Already a member?" style="title2"></SMLLabel>
                  <button
                    type="button"
                    className="btn-no-margin button1"
                    onClick={handleLoginClick}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <TermsAndConditions
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Terms and Conditions"
        />
      </div>
      <div className="signUp-footer">
        <div onClick={handleBackClick} className="back-btn">
          <Backbutton />
        </div>
        <div onClick={handleContactInformation}>
          <SMLButton
            style={`SMLButton2 SMLButton-Orange ${
              agreeConditions ? "" : "disabled"
            }`}
            value="Next"
            handleDisable={!agreeConditions}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
