import axios from "axios";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
// Function to artist Profile Update
export const putArtist = async ({ updates }) => {
  try {
    const accessToken = localStorage.getItem("token");
    const artistId = updates._id;
    const url = `${BASE_URL}/profiles/artists/${artistId}`;
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${accessToken}`, //
        "Content-Type": "application/json",
      },
      data: JSON.stringify(updates),
    };

    const response = await axios.request({ url, ...config });
    return response?.data;
  } catch (error) {
    console.error(
      "Error updating",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
