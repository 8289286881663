import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
//Function to Forget Password
export const forgetPassApi = async (data) => {
  try {
    const formData = { email: data };
    const url = `${BASE_URL}/auth/reset-password`;
    const response = await api.post(url, formData);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error);
    throw error;
  }
};

export const forgetPassFinalApi = async (data) => {
  try {
    const formData = {
      email: data.email,
      verification_code: data.code,
      new_password: data.password,
    };
    const url = `${BASE_URL}/auth/confirm-reset-password`;
    const response = await api.post(url, formData);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error);
    throw error;
  }
};
