import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SMLLabel from "../../Shared/SMLLabel/SMLLabel";
import closeIcon from "../../../assets/Images/closeIcon.svg";
import SMLTextBox from "../../Shared/SMLTextBox/SMLTextBox";
import SMLButton from "../../Shared/SMLButton/SMLButton";
import { useDispatch, useSelector } from "react-redux";
import { forgetPasswordRequest } from "../../../redux/actions";
import { useNavigate } from "react-router-dom"; 
import "./ForgetPassword.css";
import { RESET_FORGET_PASSWORD } from "../../../redux/actions/actionTypes";


//forget pass popup
//TODO : RESET FORGET PASS if needed
const ForgetPassword = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNextPage, setShowNextPage] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOtp(null);
    if (!email.trim()) {
      setError("Email is required");
      return;
    } else if (!validateEmail(email)) {
      setError("Invalid Email format");
      return;
    } else {
      setError("");
    }
    dispatch(forgetPasswordRequest(email));
  };

  const message = useSelector(
    (state) => state.forgetPass?.resetPass?.message?.message
  );
  const resetPass = useSelector((state) => state?.forgetPass?.resetPass);
  const resetError = useSelector((state) => state.forgetPass?.resetPass?.error);
  useEffect(() => {
    if (message && message.length !== 0) {
      setShowMessage(true);
    
    } else {
      if (resetPass?.isError && resetPass?.error?.length !== 0) {
        
        setError(resetError?.message);
      }
    }
  }, [message, resetPass, navigate]);

 

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      open={true}
      centered
      onClose={onClose}
      className="forgot-pass-modal"
    >
      <div className="forgot-password-container">
        <div className="forgot-password-card">
          <div className="forgot-password-header">
            <SMLLabel value={"Forgot Password?"} style="Mainheader" />
            <div className="forgot-pass-close">
              <img
                src={closeIcon}
                style={{ cursor: "pointer" }}
                alt="Close"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="forgot-pass-form-group">
            <div>
              <div className="mb-3">
                <SMLLabel
                  style="title2"
                  value="Enter your email to reset your password"
                />
              </div>
              <div>
                <SMLTextBox
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="forget-pass-error">
                {error && <SMLLabel value={error} style="error" />}
                {showMessage && (
                  <SMLLabel value={message} style="repsonsetitle" />
                )}
              </div>
            </div>
          </div>
          <>
            <div className="forget-pass-btn">
              <SMLButton
                type="submit"
                style={`SMLButton2 SMLButton-Orange ${
                  validateEmail(email) || showMessage ? "" : "disabled"
                }`}
                value="Send Code"
                handleClickEvent={handleSubmit}
              />
            </div>
          </>
        </div>
      </div>
    </Modal>
  );
};

export default ForgetPassword;
