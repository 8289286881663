import React from "react";
import "../../../styles/Fonts.scss";
import "../../../styles/HTMLControls.scss";
import "./SMLLabel.css";

function SMLLabel(props) {
  return (
    <div className="sml-label-wrapper">
      <label
        className={`${props.style} ${props.resTitle ? "title_responsive" : ""}`}
      >
        {props.value}
      </label>
    </div>
  );
}

export default SMLLabel;
