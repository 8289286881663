import React, { useEffect, useState } from "react";
import HomeLayout from "../../components/Layout/HomeLayout";
import StyledHeader from "../../components/common/styled-components/StyledHeader";
import UserAvatar from "../../assets/Images/UserAvatar.svg";
import SMLButton from "../../components/Shared/SMLButton/SMLButton";
import SMLLabel from "../../components/Shared/SMLLabel/SMLLabel";
import SMLOptionQuestion from "../../components/Shared/SMLOptionQuestion/SMLOptionQuestion";
import SMLToggleButton from "../../components/Shared/SMLToggleButton/SMLToggleButton";
import Styles from "./MyProfile.module.css";
import "./MyProfile.css";
import GoogleIcon from "../../assets/Images/googleIcon.svg";
import FacebookIcon from "../../assets/Images/facebookIcon.svg";
import EditIcon from "../../assets/Images/editIon.svg";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../assets/Images/closeIcon.svg";
import {
  getAllArtistFetch,
  updateArtistRequest,
} from "../../redux/actions/smlActions";
import SMLTextBox from "../../components/Shared/SMLTextBox/SMLTextBox";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import {
  resetPasswordRemove,
  resetPasswordRequest,
} from "../../redux/actions/authActions";
import ChoosePlan from "../SignUpPage/ChoosePlan/ChoosePlan";
import PaymentPopup from "../../components/common/payment-popup/PaymentPopup";
import ViewPlanSuccess from "./ViewPlan/ViewPlanSuccess";
import { Bars } from "react-loader-spinner";
import {
  RESET_CREATE_SUB,
  RESET_GET_CLIENT,
  RESET_PB_KEY,
} from "../../redux/actions/actionTypes";
import { Modal } from "react-bootstrap";

export default function MyProfile() {
  const dispatch = useDispatch();

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  var userAvatar = UserAvatar;

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const [optionSelected, setOptionSelected] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [viewplanSelected, setviewplanSelected] = React.useState(false);
  const [connectActive, setconnectActive] = React.useState(false);
  const [disconnectActive, setdisconnectActive] = React.useState(false);
  const [addLinkActive, setaddLinkActive] = React.useState(false);
  const [addPhotoActive, setaddPhotoActive] = React.useState(false);
  const [email, setEmail] = useState("");
  const [editFlag, setEditFlag] = React.useState(false);
  const [username, setUsername] = useState("");
  const [editUsername, setEditUsername] = useState(false);
  const [avatar, setAvatar] = useState(
    localStorage.getItem("avatar") || UserAvatar
  );
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  const [role, setRole] = useState([]);

  const [isEditingExperience, setIsEditingExperience] = useState(false);
  const [experienceText, setExperienceText] = useState("");
  const [guidanceText, setGuidanceText] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordApiError, setResetPasswordApiError] = useState("");
  const [PasswordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [isEditingGuidance, setIsEditingGuidance] = useState(false);
  const [hasSyncExperience, setHasSyncExperience] = useState(false);
  const [showViewPlan, setShowViewPlan] = useState(false);
  const [links, setLinks] = useState([]);
  const [isEditingLinks, setIsEditingLinks] = useState([]);
  const [linkErrors, setLinkErrors] = useState("");
  const [isYearly, setIsYearly] = useState("");
  var userAvatar = UserAvatar;
  var googleIcon = GoogleIcon;
  var facebookIcon = FacebookIcon;
  var editIcon = EditIcon;
  const sml = useSelector((store) => store.sml);
  const resetPasswordState = useSelector((store) => store.auth?.resetPassword);
  const resetPasswordStateError = useSelector((store) => store.auth?.error);
  const artists = useSelector((store) => store.sml.allArtistFetched?.artists);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [index, setIndex] = useState();
  const [currentComponent, setCurrentComponent] = useState("plan");
  const loading = useSelector((store) => store.auth?.loading);
  const update_loading = useSelector((store) => store.sml?.loading);
  const onNextClick1 = (plan, index, isYearly) => {
    setSelectedPlanData(plan);
    setIndex(index);
    setIsYearly(isYearly);
    setCurrentComponent("payment");
  };
  const onNextClick2 = () => {
    setCurrentComponent("welcome");
  };
  useEffect(() => {
    if (resetPasswordState) {
      setResetPassword(false);
      setPasswordResetSuccess(true);
      setTimeout(() => {
        setPasswordResetSuccess(false);
        dispatch(resetPasswordRemove());
      }, 10000);
    }
  }, [resetPasswordState]);
  useEffect(() => {
    if (resetPasswordStateError) {
      setResetPasswordApiError(resetPasswordStateError);
      setTimeout(() => {
        setResetPasswordApiError("");
      }, 10000);
    }
  }, [resetPasswordStateError]);
  const handleCancelReset = () => {
    setResetPassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };
  const handleConfirmPassword = (e) => {
    if (!oldPassword.trim()) {
      setResetPasswordError("old Password is required");
      return;
    } else {
      setResetPasswordError("");
    }

    if (!newPassword.trim()) {
      setResetPasswordError("new Password is required");
      return;
    } else {
      setResetPasswordError("");
    }
    if (!confirmPassword.trim()) {
      setResetPasswordError("confirm Password is required");
      return;
    } else {
      setResetPasswordError("");
    }
    if (newPassword !== confirmPassword) {
      setResetPasswordError("password does not match");
      return;
    } else {
      setResetPasswordError("");
    }
    dispatch(resetPasswordRequest(oldPassword, newPassword));
  };

  const artistResponsse = useEffect(() => {
    dispatch(getAllArtistFetch());
  }, [dispatch]);
  useEffect(() => {
    if (artists) {
      setExperienceText(artists?.music_experience_info || "");
      setGuidanceText(artists?.guidance_needed_info || "");
      setUsername(artists?.name || "");
      setEmail(artists?.email || "");
      setLinks(artists?.personal_links || []);
      setIsEditingLinks(
        Array(artists?.personal_links?.length || 0).fill(false)
      );
      setRole(artists?.role || []);
      setHasSyncExperience(artists?.experience_with_sync || false);

      const updatedOptionSelected = [
        artists?.role?.includes("Vocalist"),
        artists?.role?.includes("Musician"),
        artists?.role?.includes("Songwriter"),
        artists?.role?.includes("Producer"),
        artists?.role?.includes("Composer"),
        artists?.role?.includes("Lyricists"),
      ];
      setOptionSelected(updatedOptionSelected);
      console.log("is editing links before", isEditingLinks);
    }
  }, [artists]);

  useEffect(() => {
    const savedAvatar = localStorage.getItem("avatar");
    if (savedAvatar) {
      setAvatar(savedAvatar);
    }
  }, []);

  const handleRolesOption = (index, option) => {
    const newOptionSelected = [...optionSelected];
    newOptionSelected[index] = !newOptionSelected[index];
    setOptionSelected(newOptionSelected);

    if (!newOptionSelected[index]) {
      setRole((prevRoles) => prevRoles.filter((el) => el !== option));
    } else {
      setRole((prevRoles) => [...prevRoles, option]);
    }
  };

  const handleClickOption = (option) => {
    if (option === "viewPlan") {
      setviewplanSelected(!viewplanSelected);
    }
    if (option === "addLink") {
      setaddLinkActive(!addLinkActive);
      setLinks([...links, ""]);
      setIsEditingLinks([...isEditingLinks, true]);
    }
    if (option === "connect") {
      setconnectActive(!connectActive);
    }
    if (option === "disconnect") {
      setdisconnectActive(!disconnectActive);
    }
    if (option === "addPhoto") {
      setaddPhotoActive(!addPhotoActive);
    }
  };
  const navigate = useNavigate();
  console.log("is editing links after", isEditingLinks);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isEmailValid) {
      setEditFlag(false);
    }
  };
  const handleViewPlan = () => {
    dispatch({ type: RESET_PB_KEY });
    dispatch({ type: RESET_GET_CLIENT });
    dispatch({ type: RESET_CREATE_SUB });
    setShowViewPlan(true);
  };
  const handleCloseViewPlan = () => {
    setShowViewPlan(false);
    setCurrentComponent("plan");
    setIndex(null);
    setSelectedPlanData(null);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        setAvatar(result);
        localStorage.setItem("avatar", result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateEmailFormat = (email) => {
    const re = /^[a-zA-Z0-9]+@gmail\.(com|in)$/;
    return re.test(email);
  };

  const handleExperienceEdit = () => {
    setIsEditingExperience(true);
  };

  const handleExperienceSave = () => {
    setIsEditingExperience(false);
  };

  const handleExperienceBlur = () => {
    setIsEditingExperience(false);
  };

  const handleKeysDown = (event, func, funcParams = []) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent adding a new line
      func && func(...funcParams);
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };

  const handleUsernameSave = () => {
    setEditUsername(false);
  };

  const handleExperienceTextChange = (event) => {
    const text = event.target.value;
    if (text.length <= 500) {
      setExperienceText(text);
    }
  };
  const handleGuidanceTextChange = (event) => {
    const text = event.target.value;
    if (text.length <= 500) {
      setGuidanceText(text);
    }
  };

  const handleGuidanceEdit = () => {
    setIsEditingGuidance(true);
  };

  const handleGuidanceSave = () => {
    setIsEditingGuidance(false);
  };

  const handlekey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent adding a new line
      handleGuidanceSave();
    }
  };
  const handleEdit = (event) => {
    const newEmail = event.target.value;
    setEmail(event.target.value);
  };

  // To fetch ME data
  let sendDatas = {
    _id: artists?._id,
    email: artists?.email,
    name: artists?.name,
    role: artists?.role,
    experience_with_sync: artists?.experience_with_sync,
    sml_lead_info: artists?.sml_lead_info,
    personal_links: artists?.personal_links,
    music_experience_info: artists?.experienceText,
    guidance_needed_info: artists?.guidance_needed_info,
    created_at: artists?.created_at,
    updated_at: artists?.updated_at,
    _v: artists?._v,
  };
  const updatedId = useSelector((state) => state.sml.updates?.updates?._id);
  useEffect(() => {
    if (updatedId && updatedId.length > 0) {
      dispatch(getAllArtistFetch());
    }
  }, [updatedId]);
  const validateLinks = (links) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        '((([a-zA-Z0-9$-_@.&+!*"(),]|[a-zA-Z0-9]))+' + // domain name and possible subdomains
        "(\\.[a-zA-Z]{2,})+)" + // top-level domain
        "(\\:\\d+)?(\\/.*)?$", // port and path
      "i"
    );

    return links.every((link) => urlPattern.test(link));
  };

  const handleProfileUpdate = (e) => {
    console.log(links);

    if (!validateLinks(links)) {
      setLinkErrors("Make sure you enter valid url in the link.");
      return;
    } else {
      setLinkErrors("")
      setIsEditingLinks(false);
      setIsEditingExperience(false);
      setIsEditingGuidance(false);
      setEditUsername(false);
      setEditFlag(false);

      const sendData = {
        _id: sendDatas._id,
        email: email,
        name: username,
        role: role,
        experience_with_sync: hasSyncExperience,
        sml_lead_info: sendDatas.sml_lead_info,
        personal_links: links,
        music_experience_info: experienceText,
        guidance_needed_info: guidanceText,
        created_at: sendDatas.created_at,
        updated_at: sendDatas.updated_at,
        _v: sendDatas._v,
      };

      dispatch(updateArtistRequest(sendData));
    }
  };

  const handleSaveLink = (index) => {
    const link = links[index];
    const newLinkErrors = [...linkErrors];
    if (validateLinkFormat(link)) {
      newLinkErrors[index] = "";
      const newIsEditingLinks = [...isEditingLinks];
      newIsEditingLinks[index] = false;
      setIsEditingLinks(newIsEditingLinks);
    } else {
      newLinkErrors[index] = "Invalid link format";
    }
    setLinkErrors(newLinkErrors);
  };

  const handleLinkSave = (value, index) => {
    setLinks((prevstate) => {
      const newState = [...prevstate];
      newState[index] = value; // Save the new value in the links array
      return newState;
    });

    setIsEditingLinks((prevState) => {
      const newState = [...prevState];
      newState[index] = false; // Disable editing mode for the link after saving
      return newState;
    });
  };

  const handleDown = (event, index, saveFunction) => {
    if (event.key === "Enter") {
      saveFunction(index);
    }
  };

  const validateLinkFormat = (link) => {
    const regex = /^www\.[a-zA-Z]+\.[cC][oO][mM]$/;
    return regex.test(link);
  };

  const handleEditLink = (index) => {
    setIsEditingLinks((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const isFacebookLoggedIn =
    localStorage.getItem("isFacebookLoggedIn") === "true";
  const isGoogleLoggedIn = localStorage.getItem("isGoogleLoggedIn") == "true";

  const disconnectFromFacebook = () => {
    localStorage.removeItem("isFacebookLoggedIn");
    localStorage.removeItem("emails");
    navigate("/");
  };
  const disconnectFromGoogle = () => {
    localStorage.removeItem("isGoogleLoggedIn");
    localStorage.removeItem("emails");
    navigate("/");
  };
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `
              `,
            },
          }
        );
        const profile = await res.json();
        localStorage.setItem("username", profile.name);
        localStorage.setItem("emails", profile.email);
        localStorage.setItem("isGoogleLoggedIn", "true");

        navigate("/dashboard");
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    },
  });
  const responseFacebook = (response) => {
    console.log(response);
    const profile = { name: response.name };
    localStorage.setItem("username", profile.name);
    localStorage.setItem("emails", profile.email);
    localStorage.setItem("isFacebookLoggedIn", "true");

    navigate("/dashboard");
  };
  const handleFacebookLogin = () => {
    disconnectFromGoogle();
  };

  const handleGoogleLogin = () => {
    disconnectFromFacebook();
    login();
  };
  const handleBack = () => {
    setCurrentComponent("plan");
  };
  const artistLoading = useSelector(
    (state) => state.sml?.allArtistFetched?.isLoading
  );
  return (
    <HomeLayout applyScroll={true}>
      {(loading || update_loading || artistLoading) && (
        <div className="spinner-overlay">
          <Bars
            height="80"
            width="80"
            color="#23f0c7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <div className="myProfileContainer-Header">
        <StyledHeader>My Profile</StyledHeader>
        <SMLButton
          value="Update profile"
          valueStyle="3"
          style="SMLSecondaryButton saveBtn"
          handleClickEvent={handleProfileUpdate}
        ></SMLButton>
      </div>
      <div className={Styles.myProfileContainer}>
        <div className={Styles.myProfileSubContainer}>
          <div className={Styles.statusCard}>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                flex: 1,
              }}
            >
              <img style={{ width: "100px" }} src={avatar} alt="User Avatar" />
              {editUsername ? (
                <div>
                  <textarea
                    className="textarea no-border-input"
                    style={{ resize: "none" }}
                    value={username}
                    onChange={handleUsernameChange}
                    onKeyDown={(e) => handleKeysDown(e, handleUsernameSave)}
                    autoFocus
                  />
                </div>
              ) : (
                <div>
                  <label
                    className="title2"
                    style={{ marginBottom: "0px", marginRight: "6px" }}
                  >
                    {username}
                  </label>
                  <img
                    src={editIcon}
                    alt="edit"
                    onClick={() => setEditUsername(true)}
                  />
                </div>
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
            />
            <SMLButton
              valueStyle="Button3"
              style="SMLSecondaryButton"
              value="Add Photo"
              handleClickEvent={() =>
                document.getElementById("fileInput").click()
              }
            />
          </div>

          <div className={Styles.statusCard}>
            <div
              style={{
                display: "flex",
                gap: 15,
                alignItems: "center",
                flex: 1,
              }}
            >
              <SMLLabel style="title2" value="Email" />
            </div>
            <div>
              {/* {editFlag ? (
                <div>
                  <input
                    type="text"
                    value={email}
                    onChange={handleEdit}
                    className="no-border-input"
                    style={{ borderColor: isEmailValid ? "" : "red" }}
                  />

                  {!isEmailValid && (
                    <div style={{ color: "red" }}>{emailError}</div>
                  )}
                </div>
              ) : (
                <div> */}
              <label style={{ marginRight: "8px" }}>{artists?.email}</label>
              {/* <img
                src={editIcon}
                alt="edit"
                onClick={() => setEditFlag(true)}
              />
              </div>
              )} */}
            </div>
          </div>
          <div className={Styles.statusCardPassword}>
            <div className="d-flex w-100">
              <div className="profile-password-card">
                <div className="profile-fields row">
                  <div className="col-10">
                    <div className="form-group">
                      <SMLLabel style="title2" value="Password" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-group profile-password-card-value">
                      <SMLLabel value="" />
                    </div>
                  </div>
                </div>
              </div>
              {!resetPassword && (
                <div style={{ width: "42%", minWidth: "139px" }}>
                  <SMLButton
                    valueStyle="Button3"
                    style="SMLSecondaryButton"
                    value="Reset Password"
                    handleClickEvent={() => {
                      setResetPassword(true);
                      setPasswordResetSuccess(false);
                    }}
                  />
                </div>
              )}
            </div>
            {PasswordResetSuccess && (
              <span
                style={{
                  "text-align": "end",
                  width: "100%",
                  color: "green",
                }}
              >
                Password changed successfully
              </span>
            )}

            <div className={`reset-password ${resetPassword ? " " : "d-none"}`}>
              <div className="resetpasswordfield">
                <SMLLabel value="Old Password" style="title2"></SMLLabel>
                <SMLTextBox
                  value={oldPassword}
                  type={showOldPassword ? "text" : "password"}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <span
                  className="toggle-password-visibility"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </span>
              </div>
              <div className="resetpasswordfield">
                <SMLLabel value="New Password" style="title2"></SMLLabel>
                <SMLTextBox
                  value={newPassword}
                  type={showNewPassword ? "text" : "password"}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <span
                  className="toggle-password-visibility"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </span>
              </div>
              <div className="resetpasswordfield">
                <SMLLabel value="Confirm Password" style="title2"></SMLLabel>
                <SMLTextBox
                  value={confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  className="toggle-password-visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <SMLButton
                  valueStyle="Button3"
                  style="SMLSecondaryButton"
                  value="Cancel"
                  handleClickEvent={() => {
                    handleCancelReset();
                  }}
                />
                <SMLButton
                  valueStyle="Button3"
                  style="SMLSecondaryButton"
                  value="Confirm Password"
                  handleClickEvent={() => handleConfirmPassword()}
                />
              </div>
              {resetPasswordApiError !== "" && (
                <span
                  style={{
                    "text-align": "end",
                    width: "100%",
                    color: "red",
                  }}
                >
                  {`${resetPasswordApiError}`}
                </span>
              )}
              {resetPasswordError && (
                <span className="error d-flex justify-content-end">
                  {resetPasswordError}
                </span>
              )}
            </div>
          </div>

          <div className={Styles.statusCard}>
            <div className="profile-links-card">
              <div className="form-group" style={{ gridColumn: "1/2" }}>
                <SMLLabel style="title2" value="Links" />
              </div>

              {/* LINKS */}
              <div style={{ gridColumn: "2/3" }}>
                {linkErrors && <div className="error">{linkErrors}</div>}
                {links.map((link, index) => (
                  <div
                    className="profile-fields row"
                    key={index}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div className="col-12">
                      <div className="form-group profile-password-card-value">
                        {isEditingLinks[index] ? (
                          <>
                            <SMLTextBox
                              type="text"
                              value={link}
                              onChange={(e) => {
                                const newLinks = [...links];
                                newLinks[index] = e.target.value;
                                setLinks(newLinks);
                              }}
                              onKeyDown={(e) => {
                                handleKeysDown(e, handleLinkSave, [
                                  link,
                                  index,
                                ]);
                              }}
                              imgSrc={closeIcon}
                              onImgClick={() => handleRemoveLink(index)}
                            />
                          </>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <SMLLabel value={link} />
                            <img
                              src={editIcon}
                              alt="Edit"
                              onClick={() => handleEditLink(index)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* ADD LINK */}
              <div
                className="profile-fields row"
                style={{ gridColumn: "2/3", justifyContent: "flex-end" }}
              >
                <div className="col-9"></div>
                <div
                  className="col-3"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <SMLOptionQuestion
                    type="button"
                    style={
                      addLinkActive
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleClickOption("addLink")}
                    value="Add Link"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.statusCard}>
            <div className="profile-plan-card">
              <div className="row profile-plan-card-wrapper">
                <div
                  className="col-7"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div className="form-group">
                    <SMLLabel style="title2" value="Plan" />
                  </div>
                </div>
                <div
                  style={{
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <div className="col-2">
                    <SMLLabel value="Basic" />
                  </div>
                  <div
                    className="col-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <SMLOptionQuestion
                      type="button"
                      style={
                        viewplanSelected
                          ? "SMLSecondaryButtonSelected"
                          : "SMLSecondaryButton"
                      }
                      handleClickEvent={handleViewPlan}
                      value="View Plan"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECOND CONTAINER */}
        <div className={Styles.myProfileSubContainer1}>
          <div className={Styles.profileCard}>
            <div className="profile-buttons profile-fields">
              <div className="row profile-fields">
                <SMLLabel value="Who are you?" style="title2"></SMLLabel>
                <div className="form-group col-12">
                  <SMLOptionQuestion
                    type="button"
                    index="0"
                    style={
                      optionSelected[0]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(0, "Vocalist")}
                    value="Vocalist"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="1"
                    style={
                      optionSelected[1]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(1, "Musician")}
                    value="Musician"
                  />

                  <SMLOptionQuestion
                    type="button"
                    index="2"
                    style={
                      optionSelected[2]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(2, "Songwriter")}
                    value="Songwriter"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="3"
                    style={
                      optionSelected[3]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(3, "Producer")}
                    value="Producer"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="4"
                    style={
                      optionSelected[4]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(4, "Composer")}
                    value="Composer"
                  />
                  <SMLOptionQuestion
                    type="button"
                    index="5"
                    style={
                      optionSelected[5]
                        ? "SMLSecondaryButtonSelected"
                        : "SMLSecondaryButton"
                    }
                    handleClickEvent={() => handleRolesOption(5, "Lyricists")}
                    value="Lyricists"
                  />
                </div>
              </div>
            </div>
            <div class="profile-experience profile-fields">
              <div className="row profile-fields">
                <div className="col-2">
                  <SMLToggleButton
                    value="Yes"
                    checked={hasSyncExperience}
                    onChange={() => setHasSyncExperience(!hasSyncExperience)}
                  />
                </div>
                <div className="col-10">
                  <SMLLabel
                    value="Do you have experience with sync?"
                    style="title2"
                  ></SMLLabel>
                </div>
              </div>
            </div>
            <div
              className="profile-aboutYouSelf "
              style={{ "border-bottom": "1.5px solid #E9EAEC" }}
            >
              <div className="row profile-fields">
                <div className="col-8">
                  <SMLLabel
                    value="Tell us about your music experience."
                    style="title2"
                  ></SMLLabel>
                </div>
                <div className="col-4 text-end">
                  <img
                    src={editIcon}
                    alt="Edit"
                    onClick={handleExperienceEdit}
                  />
                </div>
              </div>

              <div className="row profile-fields">
                <div className="col-12">
                  {isEditingExperience ? (
                    <>
                      <textarea
                        className="textarea no-border-input"
                        value={experienceText}
                        onChange={handleExperienceTextChange}
                        onKeyDown={(e) =>
                          handleKeysDown(e, handleExperienceSave)
                        }
                        autoFocus
                        maxLength="500"
                      />
                      <div>
                        {500 - experienceText?.length} characters remaining
                      </div>
                    </>
                  ) : (
                    <p className={Styles.paragraph}>{experienceText}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="profile-aboutYouSelf">
              <div className="row profile-fields">
                <div className="col-8">
                  <SMLLabel
                    value="What kind of guidance are you looking for?"
                    style="title2"
                  ></SMLLabel>
                </div>
                <div className="col-4 text-end">
                  <img src={editIcon} alt="Edit" onClick={handleGuidanceEdit} />
                </div>
              </div>
              <div className="row profile-fields">
                <div className="col-12">
                  {isEditingGuidance ? (
                    <>
                      <textarea
                        className="textarea no-border-input"
                        value={guidanceText}
                        onChange={handleGuidanceTextChange}
                        onBlur={handleGuidanceSave}
                        onKeyDown={handlekey}
                        autoFocus
                        maxLength="500"
                      />
                      <div>
                        {500 - experienceText.length} characters remaining
                      </div>
                    </>
                  ) : (
                    <p className={Styles.paragraph}>{guidanceText}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showViewPlan && (
          <div className="popups-blur">
            <div className="Viewplan-popup">
              <Modal
                className="custom-modal"
                show={true}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <div className="signUp-container">
                  <div className="signup-card">
                    {currentComponent === "plan" && (
                      <ChoosePlan
                        closePopup={handleCloseViewPlan}
                        upgradePlan="upgrade"
                        onNextClick1={onNextClick1}
                        planIndex={index}
                        isYearly={isYearly}
                      />
                    )}
                    {currentComponent === "payment" && (
                      <PaymentPopup
                        selectedPlan={selectedPlanData}
                        closePopup={handleCloseViewPlan}
                        nextClick={onNextClick2}
                        backClick={handleBack}
                        forUpgrade={true}
                        isYearly={isYearly}
                      />
                    )}
                    {currentComponent === "welcome" && (
                      <ViewPlanSuccess
                        upgradePlan="upgrade"
                        closePopup={handleCloseViewPlan}
                      />
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </HomeLayout>
  );
}
