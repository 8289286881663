import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_FEEDBACK_RECEIVED_TRACKS_FAILURE,
  GET_FEEDBACK_RECEIVED_TRACKS_FETCH,
  GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS,
  GET_UNDER_REVIEW_TRACKS_FETCH,
  GET_UNDER_REVIEW_TRACKS_SUCCESS,
  GET_UNDER_REVIEW_TRACKS_FAILURE,
  GET_FEEDBACK_RECEIVED_TRACKS_LOADING,
  GET_UNDER_REVIEW_TRACKS_LOADING,
  DOWNLOAD_TRACK_LOADING,
  DOWNLOAD_TRACK_SUCCESS,
  DOWNLOAD_TRACK_FAILURE,
  DOWNLOAD_TRACK_REQUEST,
} from '../actions/smlActions';
import { getArtist } from '../../api/artistApi';
import {
  GET_ALL_ARTIST_FAILURE,
  GET_ALL_ARTIST_FETCH,
  GET_ALL_ARTIST_LOADING,
  GET_ALL_ARTIST_SUCCESS,
  GET_ALL_TRACK_FAILURE,
  GET_ALL_TRACK_FETCH,
  GET_ALL_TRACK_LOADING,
  GET_ALL_TRACK_SUCCESS,
  UPDATE_ARTIST_REQUEST,
  UPDATE_ARTIST_SUCCESS,
  UPDATE_ARTIST_FAILURE,
} from '../actions/actionTypes';
import { getTrack } from '../../api/track/getTracks';
import { updateArtistSuccess } from '../actions/smlActions';
import { putArtist } from '../../api/artists/putartistApi';
import { downloadTrackApi } from '../../api/track/downloadTrackApi';

const getTracks = (type) => {
  let trackPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([
        {
          id: '1',
          name: 'Name of track here',
        },
        {
          id: '2',
          name: 'Other Track',
        },
      ]);
    }, 100);
  });

  return trackPromise;
};
function* downloadTrackById(action) {
  try {
    yield put({ type: DOWNLOAD_TRACK_LOADING });
    const url = yield downloadTrackApi(action.payload);
    yield put({
      type: DOWNLOAD_TRACK_SUCCESS,
      track_url: url.audio_signed_url,
    });
  } catch (e) {
    yield put({ type: DOWNLOAD_TRACK_FAILURE, error: e });
  }
}

function* fetchArtists(action) {
  try {
    yield put({ type: GET_ALL_ARTIST_LOADING });
    const artists = yield getArtist(action.payload);
    yield put({ type: GET_ALL_ARTIST_SUCCESS, artists: artists });
  } catch (e) {
    yield put({ type: GET_ALL_ARTIST_FAILURE, error: e });
  }
}
function* fetchTracks(action) {
  try {
    yield put({ type: GET_ALL_TRACK_LOADING });
    const trackStatus = action.payload.trackStatus;
    const type = action.payload.type;
    const status = action.payload.status
    const tracks = yield call(getTrack, { trackStatus, type});
    yield put({ type: GET_ALL_TRACK_SUCCESS, tracks: tracks });
  } catch (e) {
    yield put({ type: GET_ALL_TRACK_FAILURE, error: e });
  }
}
function* fetchUnderReviewTracksTracks(action) {
  try {
    yield put({ type: GET_UNDER_REVIEW_TRACKS_LOADING });
    const trackStatus = action.payload.trackStatus;
    const type = action.payload.type;
    const tracks = yield call(getTrack, { trackStatus, type });
    yield put({ type: GET_UNDER_REVIEW_TRACKS_SUCCESS, tracks: tracks });
  } catch (e) {
    console.error('Error in fetchUnderReviewTracksTracks:', e);
    yield put({ type: GET_UNDER_REVIEW_TRACKS_FAILURE, error: e.message });
  }
}
function* fetchFeedBackReceivedTracks(action) {
  try {
    yield put({ type: GET_FEEDBACK_RECEIVED_TRACKS_LOADING });
    const trackStatus = action.payload.trackStatus;
    const type = action.payload.type;
    const tracks = yield call(getTrack, { trackStatus, type });
    yield put({ type: GET_FEEDBACK_RECEIVED_TRACKS_SUCCESS, tracks: tracks });
  } catch (e) {
    yield put({ type: GET_FEEDBACK_RECEIVED_TRACKS_FAILURE, error: e });
  }
}
function* updateArtist(action) {
  try {
    const updates = yield call(putArtist, action.payload);
    yield put(updateArtistSuccess(updates));
  } catch (e) {
    yield put({ type: UPDATE_ARTIST_FAILURE, error: e });
  }
}

function* smlSaga() {
  yield takeLatest(
    GET_FEEDBACK_RECEIVED_TRACKS_FETCH,
    fetchFeedBackReceivedTracks
  );
  yield takeLatest(GET_UNDER_REVIEW_TRACKS_FETCH, fetchUnderReviewTracksTracks);
  yield takeLatest(GET_ALL_ARTIST_FETCH, fetchArtists);
  yield takeLatest(GET_ALL_TRACK_FETCH, fetchTracks);
  yield takeLatest(UPDATE_ARTIST_REQUEST, updateArtist);
  yield takeLatest(DOWNLOAD_TRACK_REQUEST, downloadTrackById);
}
export default smlSaga;
