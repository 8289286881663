import api from "../api";
const BASE_URL = "https://c7lmwpbd57.execute-api.us-west-2.amazonaws.com/dev";
//Function to get publishable key
export const getPbKeyApi = async () => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/subscriptions/config`,
    };
    const response = await api.request(config);
    return response.data;
  } catch (error) {
    console.error("error in getting pb key", error.messsage);
    throw error;
  }
};
